/**
 * Manages the state for the TextAlerts section.
 *
 * Uses the `useReducer` hook for separation and ease of testing:
 * https://reactjs.org/docs/hooks-reference.html#usereducer
 *
 * If a state management library like redux is added in the future, this should
 * translate to it very easily.
 */
import * as actions from "./actions";

/*
Actions
- { type: "TextAlerts/FETCH_SUBBED_ALERTS" }
- { type: "TextAlerts/FETCH_SUBBED_ALERTS_FAIL", error: true, payload: Error }
- { type: "TextAlerts/FETCH_SUBBED_ALERTS_SUCCESS", payload: {[id: string]: { id: string, status: "active"|"inactive" }, ..} }

- { type: "TextAlerts/TOGGLE_SUB", payload: { alertId } }
- { type: "TextAlerts/TOGGLE_SUB_FAIL", error: true, payload: { error: Error, alertId } }
- { type: "TextAlerts/TOGGLE_SUB_SUCCESS", payload: { status: "active"|"inactive", alertId } }

- { type: "TextAlerts/DELETE_NUMBER_START" }
- { type: "TextAlerts/DELETE_NUMBER_FAIL", error: true, payload: { error: Error } }
- { type: "TextAlerts/DELETE_NUMBER_SUCCESS", payload: { result, message: string } }

- { type: "TextAlerts/ADD_NUMBER_START" }
- { type: "TextAlerts/ADD_NUMBER_FAIL", error: true, payload: { error: Error } }
- { type: "TextAlerts/ADD_NUMBER_SUCCESS", payload: { message: string } }

- { type: "TextAlerts/DISMISS_MESSAGE" }
*/

/**
 * Helper to create a message for the state.
 *
 * @param {string} message
 * @param {"success"|"warning"|"info"|"error"} severity
 */
const createMessage = (message, severity = "success") => {
  return { message, severity };
};

const NO_MESSAGE = null;

export const initialState = {
  subbedAlertsLoading: true,
  subbedAlerts: {},
  numberIsProcessing: false,
  uiMessage: NO_MESSAGE
};

/**
 * Given a state and action, returns a new state in response to the action.
 *
 * You won't need to call this directly--`useReducer`'s `dispatch` function
 * will handle it.
 *
 * For more info:
 *   - https://reactjs.org/docs/hooks-reference.html#usereducer
 */
export const textAlertsReducer = (state, action) => {
  switch (action.type) {
    // Fetching subbed text alerts actions.
    case actions.FETCH_SUBBED_ALERTS_START:
      return {
        ...state,
        subbedAlerts: {},
        subbedAlertsLoading: true
      };
    case actions.FETCH_SUBBED_ALERTS_FAIL:
      return {
        ...state,
        uiMessage: createMessage(action.payload.error.message, "error"),
        subbedAlertsLoading: false
      };
    case actions.FETCH_SUBBED_ALERTS_SUCCESS:
      return {
        ...state,
        subbedAlerts: action.payload,
        subbedAlertsLoading: false
      };

    // Toggling text alerts subscription actions.
    case actions.TOGGLE_SUB_ALERT_START:
      // Optimistically update the state. This will be undone upon failure.
      return {
        ...state,
        uiMessage: NO_MESSAGE,
        subbedAlerts: {
          ...state.subbedAlerts,
          [action.payload.alertId]: !state.subbedAlerts[action.payload.alertId]
        }
      };
    case actions.TOGGLE_SUB_ALERT_FAIL:
      // Restores the optimistic state update.
      return {
        ...state,
        uiMessage: createMessage(action.payload.error.message, "error"),
        subbedAlerts: {
          ...state.subbedAlerts,
          [action.payload.alertId]: !state.subbedAlerts[action.payload.alertId]
        }
      };
    case actions.TOGGLE_SUB_ALERT_SUCCESS:
      return {
        ...state,
        uiMessage: createMessage(action.payload.message),
        subbedAlerts: {
          ...state.subbedAlerts,
          [action.payload.result.alertId]: action.payload.result.status
        }
      };

    // Delete number actions.
    case actions.DELETE_NUMBER_START:
      return {
        ...state,
        uiMessage: NO_MESSAGE,
        subbedAlertsLoading: true,
        numberIsProcessing: true
      };
    case actions.DELETE_NUMBER_FAIL:
      return {
        ...state,
        uiMessage: createMessage(action.payload.error.message, "error"),
        subbedAlertsLoading: false,
        numberIsProcessing: false
      };
    case actions.DELETE_NUMBER_SUCCESS:
      return {
        ...state,
        uiMessage: createMessage(action.payload.message),
        subbedAlerts: {},
        numberIsProcessing: false
      };

    // Add number actions.
    case actions.ADD_NUMBER_START:
      return { ...state, uiMessage: NO_MESSAGE, numberIsProcessing: true };
    case actions.ADD_NUMBER_FAIL:
      return {
        ...state,
        uiMessage: createMessage(action.payload.error.message, "error"),
        numberIsProcessing: false
      };
    case actions.ADD_NUMBER_SUCCESS:
      return {
        ...state,
        uiMessage: createMessage(action.payload.message),
        numberIsProcessing: false
      };

    // Misc actions.
    case actions.DISMISS_MESSAGE:
      return { ...state, uiMessage: NO_MESSAGE };

    default:
      return state;
  }
};
