/**
 * Defines the actions and action creators for the text alerts interface.
 *
 * Uses the `useReducer` hook for separation and ease of testing:
 * https://reactjs.org/docs/hooks-reference.html#usereducer
 *
 * If a state management library like redux is added in the future, this should
 * translate to it very easily.
 *
 * More info:
 * - https://redux.js.org/basics/actions#actions
 * - https://github.com/redux-utilities/flux-standard-action
 */

// Action types

export const FETCH_SUBBED_ALERTS_START = "TextAlerts/FETCH_SUBBED_ALERTS_START";
export const FETCH_SUBBED_ALERTS_FAIL = "TextAlerts/FETCH_SUBBED_ALERTS_FAIL";
export const FETCH_SUBBED_ALERTS_SUCCESS =
  "TextAlerts/FETCH_SUBBED_ALERTS_SUCCESS";

export const TOGGLE_SUB_ALERT_START = "TextAlerts/TOGGLE_SUB_ALERTS_START";
export const TOGGLE_SUB_ALERT_FAIL = "TextAlerts/TOGGLE_SUB_ALERTS_FAIL";
export const TOGGLE_SUB_ALERT_SUCCESS = "TextAlerts/TOGGLE_SUB_ALERTS_SUCCESS";

export const DELETE_NUMBER_START = "TextAlerts/DELETE_NUMBER_START";
export const DELETE_NUMBER_FAIL = "TextAlerts/DELETE_NUMBER_FAIL";
export const DELETE_NUMBER_SUCCESS = "TextAlerts/DELETE_NUMBER_SUCCESS";

export const ADD_NUMBER_START = "TextAlerts/ADD_NUMBER_START";
export const ADD_NUMBER_FAIL = "TextAlerts/ADD_NUMBER_FAIL";
export const ADD_NUMBER_SUCCESS = "TextAlerts/ADD_NUMBER_SUCCESS";

export const DISMISS_MESSAGE = "TextAlerts/DISMISS_MESSAGE";

// Action creators

export const fetchSubbedAlertsStart = () => ({
  type: FETCH_SUBBED_ALERTS_START
});
export const fetchSubbedAlertsFail = error => ({
  type: FETCH_SUBBED_ALERTS_FAIL,
  error: true,
  payload: { error }
});
export const fetchSubbedAlertsSuccess = result => {
  // The API returns the list of subbed text alerts in an odd format. Clean it
  // up for our state.

  /*
  Convert this:

      { wsb_weather: { id: "wsb_weather", status: "active" }, ...}

  Into this:

      { wsb_weather: true, ... }
  */
  const resultList = Object.values(result);

  let resultObj = {};
  for (const { id, status } of resultList) {
    resultObj[id] = status === "active";
  }

  return {
    type: FETCH_SUBBED_ALERTS_SUCCESS,
    payload: resultObj
  };
};

export const toggleSubAlertStart = alertId => ({
  type: TOGGLE_SUB_ALERT_START,
  payload: { alertId }
});
export const toggleSubAlertFail = (error, alertId) => ({
  type: TOGGLE_SUB_ALERT_FAIL,
  error: true,
  payload: { error, alertId }
});
export const toggleSubAlertSuccess = (result, message) => ({
  type: TOGGLE_SUB_ALERT_SUCCESS,
  payload: {
    result: { status: result.status === "active", alertId: result.id },
    message
  }
});

export const deleteNumberStart = () => ({
  type: DELETE_NUMBER_START
});
export const deleteNumberFail = error => ({
  type: DELETE_NUMBER_FAIL,
  error: true,
  payload: { error }
});
export const deleteNumberSuccess = (
  result,
  message = "You are now unsubscribed from all text alerts."
) => ({
  type: DELETE_NUMBER_SUCCESS,
  payload: { result, message }
});

export const addNumberStart = () => ({
  type: ADD_NUMBER_START
});
export const addNumberFail = error => ({
  type: ADD_NUMBER_FAIL,
  error: true,
  payload: { error }
});
export const addNumberSuccess = (
  message = "Your phone number has been saved."
) => ({
  type: ADD_NUMBER_SUCCESS,
  payload: { message }
});

export const dismissMessage = () => ({
  type: DISMISS_MESSAGE
});
