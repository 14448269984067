import React from "react";
import { Auth } from "aws-amplify";
import { Authenticator } from "aws-amplify-react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

/* The following Authenticator components have been extended to allow UI customization
 * and in some cases, minor functionality and flow customization.
 *
 * The main method that needs to be overridden for most UI customization is showComponent()
 */
import { ExtendedSignIn } from "./SignIn";
import { ExtendedSignUp } from "./SignUp";
import { ExtendedConfirmSignUp } from "./ConfirmSignUp";
import { ExtendedForgotPassword } from "./ForgotPassword";
import { extendedProfileWrapper } from "./extendedProfileUserWrapper";
import * as qs from "query-string";

/* The extendedProfileWrapper and extendedProfileOverrideFieldsfunction lay the groundwork
 * for replacing Cognito Profiles with DynamoDB's Extended Web Profiles as the absolute
 * source of truth for user attributes.
 * For now, the phone_number and nickname are the only attributes that come from the Extended Web Profile.
 */
const extendedProfileOverrideFields = ["phone_number", "nickname"];

const signUpConfig = {
  hiddenDefaults: ["phone_number"],
  signUpFields: [
    {
      label: "Tenant",
      key: "signup_tenant",
      required: false,
      type: "string",
      custom: true
    },
    {
      label: "Email Address",
      key: "email",
      required: true,
      type: "string"
    },
    {
      label: "User Name",
      key: "username",
      required: true,
      type: "string"
    },
    {
      label: "Password",
      key: "password",
      required: true,
      type: "string"
    },
    {
      label: "Display Name",
      key: "nickname",
      required: true,
      type: "string"
    },
    {
      label: "First Name",
      key: "given_name",
      required: true,
      type: "string"
    },
    {
      label: "Last Name",
      key: "family_name",
      required: true,
      type: "string"
    },
    {
      label: "Birth Date",
      key: "birthdate",
      required: true,
      type: "string"
    },
    {
      label: "Zip Code",
      key: "zip_code",
      required: true,
      type: "string",
      custom: true
    },
    {
      label:
        "By submitting your registration information, you agree to our Visitor Agreement and Privacy Policy.",
      key: "policyConsent",
      required: true,
      type: "string",
      custom: true
    }
  ]
};

class ExtendedAuthenticator extends Authenticator {
  render() {
    return (
      <>
        {super.render()}
        <Snackbar
          open={this.state.showToast}
          autoHideDuration={15000}
          onClose={() => this.setState({ showToast: false })}
        >
          <Alert severity="error">{this.state.error}</Alert>
        </Snackbar>
      </>
    );
  }
}

export class AuthenticatorWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    // On state change, <Authenticator/> re-renders <PostAuthLogic/> and then triggers
    // an <App/> state change via updateAuthProps, which triggers an <App/> re-render.
    // There's no need to re-render <PostAuthLogic/> in such case.

    if (
      nextProps.authProps &&
      this.lastAuthenticatorUpdate !== null &&
      nextProps.authProps.lastAuthenticatorUpdate ===
        this.lastAuthenticatorUpdate
    ) {
      this.lastAuthenticatorUpdate = null;
      return false;
    }

    return true;
  }

  render() {
    const appProps = this.props;
    const authenticatorProps = {
      hideDefault: true,
      onStateChange: async (authState, authData) => {
        this.lastAuthenticatorUpdate = Date.now();

        if (
          authState === "signedIn" &&
          authData &&
          typeof authData !== "string" &&
          !authData.attributes
        ) {
          authData.attributes = {};

          // Amplfiy bug leads to the Authenticator returning from social authentication with a user
          // without attributes.
          // https://github.com/aws-amplify/amplify-js/issues/3214
          // This delay fetched user will eventually fetch the user that has attributes
          const t = setInterval(async () => {
            const user = await Auth.currentAuthenticatedUser();
            if (user.attributes && user.attributes.email) {
              clearInterval(t);
              const extendedProfileWrappedUser = await extendedProfileWrapper(
                user,
                extendedProfileOverrideFields
              );
              appProps.updateAuthProps(authState, extendedProfileWrappedUser);
            }
          }, 500);
        }

        const extendedProfileWrappedAuthData = await extendedProfileWrapper(
          authData,
          extendedProfileOverrideFields
        );
        appProps.updateAuthProps(authState, extendedProfileWrappedAuthData, {
          lastAuthenticatorUpdate: this.lastAuthenticatorUpdate
        });
      }
    };

    if (
      appProps.location &&
      (appProps.location.pathname === appProps.signInPath || 
        appProps.location.pathname === '/create-account')
    ) {
      const { privacyPolicy, visitorAgreement } = appProps.tenantConfig.ui.urls;
      const siteDomain = appProps.tenantConfig.meta
        ? appProps.tenantConfig.meta.domain
        : "";

      const q = qs.parse(appProps.location.search);
      let resetData = {};

      if (q.reset) {
        authenticatorProps["authState"] = "forgotPassword";
        window.history.replaceState(
          {},
          window.document.title,
          appProps.location.pathname
        );
        resetData = q;
      }

      return (
        <ExtendedAuthenticator {...authenticatorProps}>
          <ExtendedSignIn site={appProps.site} />
          <ExtendedSignUp
            signUpConfig={signUpConfig}
            privacyPolicy={privacyPolicy}
            visitorAgreement={visitorAgreement}
            site={appProps.site}
          />
          <ExtendedConfirmSignUp />
          <ExtendedForgotPassword
            reset={resetData}
            site={appProps.site}
            siteName={siteDomain}
          />
        </ExtendedAuthenticator>
      );
    } else {
      return (
        <ExtendedAuthenticator {...authenticatorProps}></ExtendedAuthenticator>
      );
    }
  }
}

export async function currentExtendedProfileWrappedUser() {
  let extendedProfileWrappedUser = {};
  try {
    extendedProfileWrappedUser = await extendedProfileWrapper(
      await Auth.currentAuthenticatedUser(),
      extendedProfileOverrideFields
    );
  } catch (err) {
    console.err("Error fetching current authenticated user", err);
  }
  return extendedProfileWrappedUser;
}
