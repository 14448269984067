import React from "react";
import { Auth } from "aws-amplify";
import { SignOut } from "aws-amplify-react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

/* Authenticator components can be extended to allow UI customization
 * and in some cases, minor functionality and flow customization.
 *
 * The main method that needs to be overridden for most UI customization is showComponent()
 */

// styles
const styles = theme => ({
  mcSignInButton: {
    ...theme.typography.mcSignInButton,
    marginLeft: "25px"
  }
});

class ExtendedSignOutThemed extends SignOut {
  findState() {
    if (!this.props.authState && !this.props.authData) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          // This fixes a bug in Amplify, where setState is called
          // on SignOut before the component is Unmounted
          // https://github.com/aws-amplify/amplify-js/blob/7dbc5b5/packages/aws-amplify-react/src/Auth/SignOut.tsx#L67
          if (this._isMounted) {
            this.setState({
              authState: "signedIn",
              authData: user,
              stateFromStorage: true
            });
          }
        })
        .catch(() => {
          console.log("User already logged out.");
        });
    } else if (this.props.stateFromStorage) {
      this.setState({
        stateFromStorage: true
      });
    }
  }

  render() {
    const authState = this.props.authState || this.state.authState;
    const { classes } = this.props;

    if (authState === "signedIn") {
      return (
        <Button
          className={classes.mcSignInButton}
          variant="contained"
          onClick={() => super.signOut()}
        >
          Sign Out
        </Button>
      );
    }

    return null;
  }
}

export const ExtendedSignOut = withStyles(styles, { withTheme: true })(
  ExtendedSignOutThemed
);
