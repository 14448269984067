import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ExtendedSignOut } from "../../extended-amplify-auth";
import greetingsName from "../../utility/greetingsName";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  useScrollTrigger,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    ...theme.toolbarMargin
  },
  logo: {
    ...theme.logo
  },
  mcSignInButton: {
    ...theme.typography.mcSignInButton
  },
  welcomeMessage: {
    color: theme.palette.primary.contrastText,
    marginLeft: "auto",
    marginRight: "25px",
    textTransform: "capitalize"
  },
  appBarNavLinks: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "28px",
    opacity: 0.7,
    color: theme.palette.primary.contrastText
  },
  appBarNavLinksSelected: {
    color: theme.palette.primary.contrastText,
    opacity: 1
  },
  drawerIconContainer: {
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  drawerIcon: {
    width: "40px",
    height: "40px"
  },
  drawer: {
    backgroundColor: theme.palette.primary.main
  },
  drawerItem: {
    ...theme.typography.tab,
    color: theme.palette.primary.contrastText,
    opacity: 0.7
  },
  drawerItemSelected: {
    ...theme.typography.tab,
    color: theme.palette.primary.contrastText,
    opacity: 1
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
    borderBottom: `1px solid ${theme.palette.secondary.main}`
  }
}));

const Header = props => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();

  const authenticated =
    props.authProps.authState === "signedIn" && props.authProps.user;

  let greeting;
  if (authenticated) {
    greeting = (
      <Typography className={classes.welcomeMessage}>
        Welcome {greetingsName(props.authProps.user)} <ExtendedSignOut />
      </Typography>
    );
  } else {
    // removing the sign in button from the sign in page
    if (location && (location.pathname !== "/sign-in" && location.pathname !== "/create-account")) {
      greeting = (
        <Button
          className={classes.mcSignInButton}
          variant="contained"
          href={props.signInPath}
        >
          Sign In
        </Button>
      );
    }
  }

  // nice little animation that raises the navbar as the user scrolls
  function ElevationScroll(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0
    });
  }

  const bpBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const bpAboveLg = useMediaQuery(theme.breakpoints.up("lg"));
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  // changing the navigation current page selected
  const [openDrawer, setOpenDrawer] = useState(false);

  const createMenuOptions = () => {
    const linkMapping = {
      "My Account": "/edit-profile",
      Newsletters: "/newsletters",
      "Text Alerts": "/text-alerts",
      "Email Alerts": "/email-alerts",
      Contests: "/contests"
    };

    // Map to link values.
    const navItems = props.tenantConfig.ui.menuConfig.nav;
    const arrangedNavItems = navItems.map(name => ({
      name,
      link: linkMapping[name]
    }));

    return arrangedNavItems;
  };

  let menuOptions = createMenuOptions();

  const tabs = (
    <>
      {menuOptions.map((option, i) => (
        <Button
          key={i}
          label={option.name}
          to={option.link}
          component={NavLink}
          className={classes.appBarNavLinks}
          activeClassName={classes.appBarNavLinksSelected}
          disableRipple
        >
          {option.name}
        </Button>
      ))}
    </>
  );

  const drawer = (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          {menuOptions.map((option, i) => (
            <ListItem
              key={i}
              onClick={() => {
                setOpenDrawer(false);
              }}
              divider
              button
              component={NavLink}
              to={option.link}
              className={classes.drawerItem}
              activeClassName={classes.drawerItemSelected}
            >
              <ListItemText disableTypography>{option.name}</ListItemText>
            </ListItem>
          ))}
        </List>
        <ExtendedSignOut />
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </>
  );

  return (
    <>
      <ElevationScroll>
        <AppBar position="fixed" color="primary" className={classes.appbar}>
          <Toolbar>
            {/* desktop breakpoint */}
            {bpAboveLg && (
              <>
                <a href={props.tenantConfig.ui.urls.backToStation}>
                  <img
                    className={classes.logo}
                    alt="site name"
                    src={props.tenantConfig.ui.theme.logo}
                  />
                </a>
                {authenticated && tabs}
                {greeting}
              </>
            )}
            {/* mobile breakpoint */}
            {bpBelowMd && (
              <div style={{ width: "100%" }}>
                <Box display="flex" alignItems="center">
                  <Box style={{ flex: 1 }}>{authenticated && drawer}</Box>
                  <Box>
                    <a href={props.tenantConfig.ui.urls.backToStation}>
                      <img
                        className={classes.logo}
                        alt="site name"
                        src={props.tenantConfig.ui.theme.logo}
                      />
                    </a>
                  </Box>
                  <Box style={{ flex: 1 }}></Box>
                </Box>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </>
  );
};

export default Header;
