import React, { useEffect, useReducer } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Paper,
  Checkbox,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PhoneNumberField from "../fields/PhoneNumberField";
import {
  fetchTextAlerts,
  setAlertSub,
  deleteNumber,
  verifyNumber,
  updatePhoneNumber
} from "./api";
import { initialState, textAlertsReducer } from "./state/reducer";
import * as actions from "./state/actions";
import { currentExtendedProfileWrappedUser } from "../../extended-amplify-auth/Authenticator";

const FETCH_SUBS_ERROR_MESSAGE =
  "Unable to get the currently subscribed text alerts.";
const SUB_ERROR_MESSAGE =
  "Unable to update subscription status. Please try again.";
const DELETE_NUMBER_ERROR_MESSAGE =
  "Unable to delete phone number. Please try again.";
const ADD_NUMBER_ERROR_MESSAGE =
  "Unable to save phone number. Please try again.";

const useStyles = makeStyles(theme => ({
  paper: {
    ...theme.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(3)
  },
  listSubheader: {
    padding: 0,
    fontSize: "1rem",
    marginTop: "0.75rem",
    lineHeight: "1.5rem",
    textTransform: "capitalize"
  },
  editNumberSection: {
    marginBottom: theme.spacing(2)
  },
  alertsSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  alertsSectionDisabled: {
    opacity: "0.5"
  },
  weatherClosingsAlertsSection: {
    marginBottom: theme.spacing(3)
  },
  disclaimerSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  mcLink: {
    ...theme.mcLink
  }
}));

/**
 * Restructures text alert list into groups for easier rendering.
 *
 * @param {object} options SMS configuration list from tenant config
 */
const groupSMSOptions = options => {
  const grouped = {};

  for (const [smsName, smsConfig] of Object.entries(options)) {
    const newConfig = { ...smsConfig, key: smsName };

    grouped[smsConfig.category]
      ? grouped[smsConfig.category].push(newConfig)
      : (grouped[smsConfig.category] = [newConfig]);
  }

  return grouped;
};

/**
 * Replaces dashes with spaces.
 *
 * Capitalization is done via CSS.
 */
const toFriendlyName = category => category.replace(/-/, " ");

/**
 * Displays the Text Alerts page.
 */
const TextAlerts = ({
  site,
  authProps,
  tenantConfig,
  manageAlertsUrl,
  updateAuthProps
}) => {
  const classes = useStyles();

  const smsConfig = tenantConfig.sms_config;
  const phoneNumber = authProps.user.attributes.phone_number || "";
  const email = authProps.user.attributes.email;
  // since sms alerts are TV only, we're catching this
  const smsTermsLink = tenantConfig.ui.urls.smsTerms
    ? tenantConfig.ui.urls.smsTerms
    : `https://www.${tenantConfig.meta.domain}`;
  const privacyPolicyLink = tenantConfig.ui.urls.privacyPolicy;

  const groupedSMSOptions = groupSMSOptions(smsConfig.lists);
  const [state, dispatch] = useReducer(textAlertsReducer, initialState);

  useEffect(() => {
    // Fetch subscribed text alerts.
    const getTextAlerts = async () => {
      // Nothing to fetch if we don't have the phone number or email.
      if (phoneNumber !== "" && email) {
        dispatch(actions.fetchSubbedAlertsStart());

        try {
          const textAlerts = await fetchTextAlerts(phoneNumber, email, site);
          if (textAlerts.success) {
            dispatch(actions.fetchSubbedAlertsSuccess(textAlerts.result));
          } else {
            dispatch(
              actions.fetchSubbedAlertsFail(new Error(FETCH_SUBS_ERROR_MESSAGE))
            );
          }
        } catch (e) {
          dispatch(
            actions.fetchSubbedAlertsFail(new Error(FETCH_SUBS_ERROR_MESSAGE))
          );
        }
      }
    };

    setTimeout(async () => await getTextAlerts(), 600);
  }, [phoneNumber, email, site]);

  /**
   * Toggles opt-in/opt-out of text alert.
   *
   * @param {string} alertId
   * @param {string} alertName User friendly text alert name
   */
  const toggleSub = async (alertId, alertName) => {
    const isSubbed = state.subbedAlerts[alertId];
    dispatch(actions.toggleSubAlertStart(alertId));

    try {
      const response = await setAlertSub(
        phoneNumber,
        email,
        site,
        alertId,
        !isSubbed
      );

      // Check `status` field.
      if (response.success) {
        const message = isSubbed
          ? `You are now unsubscribed from ${alertName}.`
          : `You are now subscribed to ${alertName}.`;
        dispatch(actions.toggleSubAlertSuccess(response.result, message));
      } else {
        dispatch(
          actions.toggleSubAlertFail(new Error(SUB_ERROR_MESSAGE), alertId)
        );
      }
    } catch (err) {
      dispatch(
        actions.toggleSubAlertFail(new Error(SUB_ERROR_MESSAGE), alertId)
      );
    }
  };

  /**
   * Handles the AddPhoneNumberDialog phone number validation.
   *
   * Uses the API's verify endpoint for validation.
   *
   * @param {string} phoneNumber The possibly invalid phone number
   * @returns {Promise<boolean>} `true` if valid; `false` if invalid
   */
  const handleValidation = async phoneNumber => {
    dispatch(actions.addNumberStart());
    let result;
    try {
      result = await verifyNumber(phoneNumber, email, site);
    } catch (e) {
      dispatch(
        actions.addNumberFail(
          new Error("There was a problem verifying phone number.")
        )
      );
    }

    if (result && !result.isValid) {
      dispatch(
        actions.addNumberFail(
          new Error("Please correct your phone number and try again.")
        )
      );
    }

    return result && result.isValid;
  };

  /**
   * Callback when the user has added a new number.
   *
   * Number has already been validated.
   */
  const onPhoneNumberAdd = async phoneNumber => {
    try {
      // Update the user's profile.
      await updatePhoneNumber(authProps.user, phoneNumber);

      // Update App's state with new user attributes.
      const updatedUser = await currentExtendedProfileWrappedUser();
      updateAuthProps(authProps.authState, updatedUser);

      dispatch(actions.addNumberSuccess());
    } catch (e) {
      console.log(e);
      dispatch(actions.addNumberFail(new Error(ADD_NUMBER_ERROR_MESSAGE)));
    }
  };

  /**
   * Callback to unsubscribe a phone number from all text alerts and remove the
   * number from their profile.
   */
  const onPhoneNumberDelete = async () => {
    dispatch(actions.deleteNumberStart());

    try {
      // Unsubscribe from mgage.
      const result = await deleteNumber(phoneNumber, email, site);

      // Update the user's profile.
      await updatePhoneNumber(authProps.user, "");

      // Update App's state with new user attributes.
      const updatedUser = await currentExtendedProfileWrappedUser();
      updateAuthProps(authProps.authState, updatedUser);

      dispatch(actions.deleteNumberSuccess(result));
    } catch (e) {
      console.log(e);
      dispatch(
        actions.deleteNumberFail(new Error(DELETE_NUMBER_ERROR_MESSAGE))
      );
    }
  };

  const optInDisabled = !phoneNumber || state.subbedAlertsLoading;
  return (
    <div>
      <Container maxWidth="sm">
        <Typography variant="h2">Text Alerts</Typography>

        <Typography variant="body1" gutterBottom>
          Sign up to receive text alerts from {tenantConfig.meta.domain}.
        </Typography>

        <Paper className={classes.paper}>
          <div className={classes.editNumberSection}>
            <Typography variant="h3" gutterBottom>
              Mobile Number
            </Typography>
            <Typography variant="body2" gutterBottom>
              Enter your mobile number to receive text alerts. *
            </Typography>

            <PhoneNumberField
              phoneNumber={phoneNumber}
              isProcessing={state.numberIsProcessing}
              onValidate={handleValidation}
              onAdd={onPhoneNumberAdd}
              onDelete={onPhoneNumberDelete}
            />
          </div>
        </Paper>

        <Paper className={classes.paper}>
          <div
            className={`${classes.alertsSection} ${optInDisabled &&
              classes.alertsSectionDisabled}`}
          >
            <Typography variant="body2" gutterBottom>
              Check below to opt-in to text alerts.
            </Typography>

            <List>
              {Object.entries(groupedSMSOptions).map(([group, textAlerts]) => (
                <div key={group}>
                  <ListSubheader
                    className={classes.listSubheader}
                    disableSticky
                  >
                    {toFriendlyName(group)}
                  </ListSubheader>
                  {textAlerts.map(textAlert => (
                    <ListItem
                      key={textAlert.key}
                      button
                      disabled={optInDisabled}
                      onClick={() => toggleSub(textAlert.key, textAlert.name)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          color="default"
                          disabled={optInDisabled}
                          checked={state.subbedAlerts[textAlert.key] || false}
                          edge="start"
                          disableRipple
                          inputProps={{ "aria-labelledby": textAlert.key }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={textAlert.key}
                        primary={textAlert.name}
                      />
                    </ListItem>
                  ))}
                </div>
              ))}
            </List>
          </div>
        </Paper>

        {manageAlertsUrl && (
          <div className={classes.weatherClosingsAlertsSection}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <Link
              className={classes.mcLink}
              href={manageAlertsUrl}
              target="_blank"
              rel="noreferrer"
            >
              Manage Severe Weather &amp; School and Business Closings Alerts
            </Link>

            <Typography variant="subtitle2">
              (This will open into a new browser window.)
            </Typography>
          </div>
        )}

        <Divider />

        <div className={classes.disclaimerSection}>
          <Typography variant="subtitle2" gutterBottom>
            * Sign me up to receive text alerts at the mobile number on my
            account. Message frequency may vary. Message and data rates may
            apply. Reply HELP for help and STOP to cancel. Read our{" "}
            <Link
              className={classes.mcLink}
              href={smsTermsLink}
              target="_blank"
              rel="noreferrer"
            >
              SMS Terms{" "}
            </Link>
            and{" "}
            <Link
              className={classes.mcLink}
              href={privacyPolicyLink}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
            .
          </Typography>

          <Typography variant="subtitle2">
            Unsubscribing from text alerts may take up to{" "}
            <strong>48 hours to process</strong>.
          </Typography>
        </div>

        <Snackbar
          open={!!state.uiMessage}
          autoHideDuration={6000}
          onClose={() => dispatch(actions.dismissMessage())}
        >
          {state.uiMessage && (
            <Alert
              severity={state.uiMessage && state.uiMessage.severity}
              onClose={() => dispatch(actions.dismissMessage())}
            >
              {state.uiMessage && state.uiMessage.message}
            </Alert>
          )}
        </Snackbar>
      </Container>
    </div>
  );
};

/**
 * Handles auth loading state and logged out state.
 *
 * This would be a good candidate for an HOC.
 *
 * @param {object} props Uses authProps and passes the rest to the wrapped component
 */
const WrappedTextAlerts = props => {
  if (props.authProps.authState) {
    if (props.authProps.isAuthenticated && props.authProps.user) {
      return (
        <div className="box cta">
          <TextAlerts {...props} />
        </div>
      );
    } else {
      return <Redirect to="/sign-in" />;
    }
  } else {
    return <span className="tag is-primary">Loading ...</span>;
  }
};

export default WrappedTextAlerts;
