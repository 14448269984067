// PRIVATE
const debugFlag = process.env.REACT_APP_DEBUG.toLowerCase();

// PUBLIC
export const LOG_TYPE = {
  ERROR: 0,
  LOG: 1,
  INFO: 2,
  WARN: 3,
  TRACE: 4
};

/**
 *
 * @param {string} message The text that is logged in the console
 * @param {number} logType The log output type: 0=error, 1=log(default), 2=info, 3=warn, 4=trace (import LOG_TYPE object for constant values)
 * @param {any} data Any additional data to log
 */
export default function log(message, logType = 1, ...data) {
  if (debugFlag === "1" || debugFlag === "true") {
    let logHandler = null;

    switch (logType) {
      case LOG_TYPE.ERROR:
        logHandler = console.error || console.log;
        break;
      case LOG_TYPE.LOG:
        logHandler = console.log;
        break;
      case LOG_TYPE.INFO:
        logHandler = console.info || console.log;
        break;
      case LOG_TYPE.WARN:
        logHandler = console.warn || console.log;
        break;
      case LOG_TYPE.TRACE:
        logHandler = console.trace || console.log;
        break;
      default:
        logHandler = console.log;
    }

    logHandler("[MemberCenter]", ...[message, ...data]);
  }
}
