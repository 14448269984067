import React from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box, SvgIcon } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  mcFacebookLogo: {
    backgroundColor: "#4267B2",
    color: theme.palette.common.white
  },
  mcGoogleLogo: {
    backgroundColor: "#4285F4",
    color: theme.palette.common.white
  },
  mcAmazonLogo: {
    backgroundColor: "#FF9900",
    color: theme.palette.common.white
  }
}));

const McOAuthButtons = () => {
  const classes = useStyles();

  return (
    <Box px={1} py={3}>
      <Box py={1}>
        <Button
          className={classes.mcFacebookLogo}
          size="medium"
          variant="contained"
          fullWidth
          onClick={() => {
            Auth.federatedSignIn({ provider: "Facebook" });
          }}
          startIcon={
            <SvgIcon>
              <path
                fill="currentColor"
                d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
              />
            </SvgIcon>
          }
        >
          Facebook
        </Button>
      </Box>
      <Box py={1}>
        <Button
          className={classes.mcGoogleLogo}
          size="medium"
          variant="contained"
          fullWidth
          onClick={() => {
            Auth.federatedSignIn({ provider: "Google" });
          }}
          startIcon={
            <SvgIcon>
              <path
                fill="currentColor"
                d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
              />
            </SvgIcon>
          }
        >
          Google
        </Button>
      </Box>
      <Box py={1}>
        <Button
          className={classes.mcAmazonLogo}
          size="medium"
          variant="contained"
          fullWidth
          onClick={() => {
            Auth.federatedSignIn({ provider: "LoginWithAmazon" });
          }}
          startIcon={
            <SvgIcon>
              <path
                fill="currentColor"
                d="M15.93,17.09C15.75,17.25 15.5,17.26 15.3,17.15C14.41,16.41 14.25,16.07 13.76,15.36C12.29,16.86 11.25,17.31 9.34,17.31C7.09,17.31 5.33,15.92 5.33,13.14C5.33,10.96 6.5,9.5 8.19,8.76C9.65,8.12 11.68,8 13.23,7.83V7.5C13.23,6.84 13.28,6.09 12.9,5.54C12.58,5.05 11.95,4.84 11.4,4.84C10.38,4.84 9.47,5.37 9.25,6.45C9.2,6.69 9,6.93 8.78,6.94L6.18,6.66C5.96,6.61 5.72,6.44 5.78,6.1C6.38,2.95 9.23,2 11.78,2C13.08,2 14.78,2.35 15.81,3.33C17.11,4.55 17,6.18 17,7.95V12.12C17,13.37 17.5,13.93 18,14.6C18.17,14.85 18.21,15.14 18,15.31L15.94,17.09H15.93M13.23,10.56V10C11.29,10 9.24,10.39 9.24,12.67C9.24,13.83 9.85,14.62 10.87,14.62C11.63,14.62 12.3,14.15 12.73,13.4C13.25,12.47 13.23,11.6 13.23,10.56M20.16,19.54C18,21.14 14.82,22 12.1,22C8.29,22 4.85,20.59 2.25,18.24C2.05,18.06 2.23,17.81 2.5,17.95C5.28,19.58 8.75,20.56 12.33,20.56C14.74,20.56 17.4,20.06 19.84,19.03C20.21,18.87 20.5,19.27 20.16,19.54M21.07,18.5C20.79,18.14 19.22,18.33 18.5,18.42C18.31,18.44 18.28,18.26 18.47,18.12C19.71,17.24 21.76,17.5 22,17.79C22.24,18.09 21.93,20.14 20.76,21.11C20.58,21.27 20.41,21.18 20.5,21C20.76,20.33 21.35,18.86 21.07,18.5Z"
              />
            </SvgIcon>
          }
        >
          Login with Amazon
        </Button>
      </Box>
    </Box>
  );
};

export default McOAuthButtons;
