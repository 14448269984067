import { API } from "aws-amplify";

/**
 * This function lays the groundwork for replacing Cognito Profiles with
 * DynamoDB's Extended Web Profiles as the absolute source of truth for
 * user attributes.
 *
 * The cognitoUser's attributes are overriden based on the list of attribute
 * names passed. The value for the attribute names comes from the Extended Web
 * Profile as the source of truth, EVEN IF THE VALUE IS FALSY OR NON-EXISTENT.
 *
 * If the list of attribute names passed is empty, then the Cognito Profile
 * attributes are erased entirely and the Extended Web Profile becomes the
 * absolute source of truth.
 *
 * The Extended Web Profile values are fetched via an API call to the
 * getProfile endpoint.
 *
 * @param {object} cognitoUser The Amplify user
 * @param {object} cognitoAttributeList A list of user attribute NAMES as strings.
 * If empty then the EWP becomes the absolute source of truth
 * @returns {object} The cognitoUser whose attributes have been overriden with
 * the Extended Web Profile attributes
 */
export async function extendedProfileWrapper(
  cognitoUser,
  cognitoAttributeList = []
) {
  if (
    !cognitoUser ||
    !cognitoUser.attributes ||
    !cognitoUser.attributes.email
  ) {
    return cognitoUser;
  }

  const cognitoAttributes = cognitoUser.attributes;
  const cognitoAttributesParam = cognitoAttributeList.length
    ? cognitoAttributeList
        .map(attr =>
          convertCustomAttributePrefix({ attr, from: "cognitoProfile" })
        )
        .join("&")
    : "*";
  let profile;
  try {
    profile = await API.get("extendedProfiles", `/${cognitoAttributes.email}`, {
      queryStringParameters: {
        userAttributesOnly: cognitoAttributesParam
      }
    });
  } catch (err) {
    console.error(
      "Failed to fetch profile data. Returning cognitoUser as received"
    );
  }

  if (profile) {
    const profileData = profile.cognitoData || {};
    if (cognitoAttributeList.length) {
      cognitoAttributeList.forEach(attr => {
        const profileValue =
          profileData[
            convertCustomAttributePrefix({ attr, from: "cognitoProfile" })
          ];
        if (typeof profileValue !== "undefined") {
          cognitoUser.attributes[attr] = profileValue;
        } else {
          delete cognitoUser.attributes[attr];
        }
      });
    } else {
      // Extended Web Profile becomes the absolute source of truth
      cognitoUser.attributes = {};
      Object.keys(profileData).forEach(attr => {
        cognitoUser.attributes[
          convertCustomAttributePrefix({ attr, from: "extendedWebProfile" })
        ] = profileData[attr];
      });
    }
  }

  return cognitoUser;
}

function convertCustomAttributePrefix({ attr, from }) {
  let extendedWebProfilePrefixMatch, cognitoProfilePrefixMatch;

  if (from === "extendedWebProfile") {
    extendedWebProfilePrefixMatch = attr.match(/^custom_(.+)$/);
  } else if (from === "cognitoProfile") {
    cognitoProfilePrefixMatch = attr.match(/^custom:(.+)$/);
  }

  let convertedAttr;
  if (extendedWebProfilePrefixMatch) {
    convertedAttr = `custom:${extendedWebProfilePrefixMatch[1]}`;
  } else if (cognitoProfilePrefixMatch) {
    convertedAttr = `custom_${cognitoProfilePrefixMatch[1]}`;
  }
  return convertedAttr || attr;
}
