import React from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@material-ui/core";

const NicknameOptions = props => {
  return (
    <Box mb={2}>
      <FormControl
        component="fieldset"
        size={"small"}
        margin={"none"}
        fullWidth={true}
        error={true}
      >
        <FormLabel component="legend">
          Display name is already taken. The following are available
        </FormLabel>
        <RadioGroup onChange={props.handleChange}>
          {props.options.map((nicknameOption, i) => (
            <FormControlLabel
              key={`nicknameOption-${i}`}
              value={nicknameOption}
              control={<Radio size={"small"} />}
              label={nicknameOption}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default NicknameOptions;
