import {
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { buildFilteredAlerts } from "./alerts";
import { getSubscriptions, postSubscription } from "./api";

const useStyles = makeStyles(theme => ({
  paper: {
    ...theme.paper
  },
  autocomplete: {
    width: "auto",
    paddingTop: theme.spacing(2)
  }
}));

/**
 * Allows a user to manage their severe weather and closings email alerts.
 */
const EmailAlerts = props => {
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const classes = useStyles();

  const subbedIds = userSubscriptions.map(s => s.emailalertId);
  const userEmail =
    (props.authProps &&
      props.authProps.user &&
      props.authProps.user.attributes.email) ||
    "";

  // var for easier access to all alerts
  const allAlertsObjs = Object.entries(
    props.authProps.tenantConfig.emailAlerts
  ).map(([k, v]) => ({
    newsletterId: k,
    display_name: v.display_name,
    tags: v.tags[0],
    status: "inactive"
  }));

  const [severeWeatherAlerts, weatherAlertsSelected] = buildFilteredAlerts(
    allAlertsObjs,
    subbedIds,
    "severe-weather"
  );

  const [closingsAlerts, closingsAlertsSelected] = buildFilteredAlerts(
    allAlertsObjs,
    subbedIds,
    "closings"
  );

  // Grab the user's subscriptions.
  useEffect(() => {
    const getAndUpdateAlerts = async email => {
      const subs = await getSubscriptions(email);

      // since all the subscriptions are in one list,
      // we have to pull out the ones that have a key of emailalertId
      setUserSubscriptions(subs.filter(sub => "emailalertId" in sub));
    };

    if (userEmail) {
      getAndUpdateAlerts(userEmail);
    }
  }, [userEmail]);

  // subscribe and unsubsribe to weather alerts
  const alertSubscribe = (values, process) => {
    if (values) {
      const newStatus = values.status === "inactive" ? "active" : "inactive";

      // Update sub via API.
      postSubscription(userEmail, values.newsletterId, newStatus);

      // Update local state to reflect change.
      setUserSubscriptions(state => {
        if (process === "add") {
          return state.concat([
            {
              email: userEmail,
              emailalertId: values.newsletterId,
              status: newStatus
            }
          ]);
        } else {
          return state.filter(sub => sub.emailalertId !== values.newsletterId);
        }
      });
    }
  };

  if (props.authProps.authState) {
    if (props.authProps.isAuthenticated && props.authProps.user) {
      const content = (
        <Container maxWidth="lg">
          <Typography variant="h2">Email Alerts</Typography>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      Severe Weather Email Alerts
                    </Typography>
                    <Typography variant="body2">
                      Type in the Locations you want to track
                    </Typography>
                    <Autocomplete
                      id="severe-weather-alerts"
                      className={classes.autocomplete}
                      options={severeWeatherAlerts}
                      getOptionSelected={(option, value) =>
                        option.newsletterId === value.newsletterId
                      }
                      getOptionDisabled={option => option.status === "active"}
                      getOptionLabel={alert => alert.display_name}
                      onChange={(_e, alert) => {
                        alertSubscribe(alert, "add");
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Severe Weather Alerts"
                          variant="outlined"
                          color="secondary"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">Locations Selected</Typography>
                    <List dense>
                      {weatherAlertsSelected.map(alert => (
                        <ListItem key={alert.newsletterId}>
                          <ListItemText>{alert.display_name}</ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => alertSubscribe(alert, "remove")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item lg={6} md={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Closings Email Alerts</Typography>
                    <Typography variant="body2">
                      Type in the names of the business you want to track
                    </Typography>
                    <Autocomplete
                      id="closings-alerts"
                      className={classes.autocomplete}
                      options={closingsAlerts}
                      getOptionSelected={(option, value) =>
                        option.newsletterId === value.newsletterId
                      }
                      getOptionDisabled={option => option.status === "active"}
                      getOptionLabel={option => option.display_name}
                      onChange={(_e, alert) => {
                        alertSubscribe(alert, "add");
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Closings Alerts"
                          variant="outlined"
                          color="secondary"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">Locations Selected</Typography>
                    <List dense>
                      {closingsAlertsSelected.map(alert => (
                        <ListItem key={alert.newsletterId}>
                          <ListItemText>{alert.display_name}</ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => alertSubscribe(alert, "remove")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      );

      return <div className="box cta">{content}</div>;
    } else {
      return <Redirect to="/sign-in" />;
    }
  } else {
    return <span className="tag is-primary">Loading ...</span>;
  }
};

export default EmailAlerts;
