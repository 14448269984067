import React from "react";
import { API } from "aws-amplify";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import noImageDefault from "../../static/images/no-default-newsletters-image.png";
import { default as debugLog, LOG_TYPE } from "../../utility/logger";

const styles = theme => ({
  root: {
    maxWidth: 345,
    margin: "10px auto"
  },
  // these styles is to make the grid item 100% of the container space
  // it also makes each card equal heights
  // if we find this is needed in more places, we'll move it to the theme
  // leaving here for now because it's component specific
  mcGridItemFull: {
    display: "flex",
    width: "100%"
  },
  mcCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  disabled: {
    opacity: 0.3,
    width: "100%"
  },
  mcCardMedia: {
    height: 0,
    paddingBottom: "56.25%"
  },
  formControl: {
    margin: "5px auto"
  },
  progressButtonWrapper: {
    ...theme.progressButtonWrapper
  },
  progressIndicator: {
    ...theme.progressIndicator,
    marginTop: 5,
    marginLeft: -24
  },
  mcCardActions: {
    marginTop: "auto"
  },
  holder: {
    minHeight: "52px",
    margin: "0 auto"
  },
  mcHidden: {
    visibility: "hidden",
    width: 0
  }
});

class NewsletterCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionStatus: "",
      isProcessing: false
    };
  }

  componentDidUpdate = previousProps => {
    if (this.props.status !== previousProps.status) {
      this.setUsersNewsletters();
    }
  };

  setUsersNewsletters = () => {
    if (this.props.status) {
      this.setState({
        subscriptionStatus: this.props.status,
        isProcessing: false
      });
    }
  };

  postNewsletterSubscription = async (
    authorized,
    newsletterid,
    subscriptionstatus,
    userEmail
  ) => {
    this.setState({ isProcessing: true });
    let response,
      apiRequestParams = [],
      consoleMsg = "";

    if (authorized) {
      apiRequestParams = [
        "userNewsletters",
        `/subscriptions/`,
        {
          queryStringParameters: {
            email: userEmail
          },
          body: {
            newsletterId: newsletterid,
            status: subscriptionstatus
          }
        }
      ];
      consoleMsg = "auth response: ";
    } else {
      apiRequestParams = [
        "newsletters",
        "/simple-signup",
        {
          body: {
            email: userEmail,
            newsletterId: newsletterid,
            status: subscriptionstatus,
            policyConsent: this.props.policyConsent
          }
        }
      ];
      consoleMsg = "unauth response: ";
    }

    try {
      response = await API.post(...apiRequestParams);

      // leaving console.log for QA
      debugLog(consoleMsg, LOG_TYPE.LOG, response);
    } catch (e) {
      this.props.showAlert(e);
    }

    if (response) {
      this.setState({
        subscriptionStatus: subscriptionstatus,
        isProcessing: false
      });
    }
  };

  authSubscribe = (authProps, newsletterName, subscriptionStatus, email) => {
    this.postNewsletterSubscription(
      authProps,
      newsletterName,
      subscriptionStatus === "inactive" ? "active" : "inactive",
      email,
      this.props.site
    );
    this.setState({ isProcessing: true });
  };

  render() {
    const { index, newsletter, authProps, userEmail, isDisabled } = this.props;
    const subscribersEmail = authProps
      ? authProps.user.attributes.email
      : userEmail;

    // we need a default image if the newsletter default image is blank
    const defaultImage =
      this.props.defaultImage !== "" ? this.props.defaultImage : noImageDefault;

    const { classes } = this.props;
    let checkboxContent = !this.state.isProcessing ? (
      <FormControl
        component="fieldset"
        className={classes.formControl}
        // we need to hide the checkbox when a simple sign in user subscribes
        // but we need to leave the Subscribed confirmation
        // so we're hiding the checkbox, showing the label, and diabling the FormControl
        disabled={
          this.state.subscriptionStatus === "active" && !authProps
            ? true
            : false
        }
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className={
                  this.state.subscriptionStatus === "active" && !authProps
                    ? `${classes.mcHidden} subscribeBtn`
                    : "subscribeBtn"
                }
                checked={this.state.subscriptionStatus === "active"}
                onChange={() => {
                  this.authSubscribe(
                    authProps,
                    newsletter.name,
                    this.state.subscriptionStatus
                      ? this.state.subscriptionStatus
                      : "inactive",
                    subscribersEmail
                  );
                }}
                name="subscribed"
              />
            }
            label={
              this.state.subscriptionStatus === "inactive" ||
              this.state.subscriptionStatus === ""
                ? "Sign Up"
                : "Subscribed"
            }
          />
        </FormGroup>
      </FormControl>
    ) : (
      <div className={classes.progressButtonWrapper}>
        <CircularProgress size={24} className={classes.progressIndicator} />
      </div>
    );

    return (
      <Grid item key={index} md={4} sm={6} className={classes.mcGridItemFull}>
        <Card className={isDisabled ? classes.disabled : classes.mcCard}>
          <CardActionArea>
            <CardMedia
              className={classes.mcCardMedia}
              image={
                newsletter.image_url !== "" &&
                newsletter.image_url !== undefined
                  ? newsletter.image_url
                  : defaultImage
              }
              title={newsletter.display_name}
            />
            <CardContent>
              <Typography gutterBottom variant="h3" color="secondary">
                {newsletter.display_name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {newsletter.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions className={classes.mcCardActions}>
            <div className={classes.holder}>
              {!isDisabled && checkboxContent}
            </div>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NewsletterCard);
