import React, { useState } from "react";
import { API, Auth } from "aws-amplify";
import isOAuthUser from "../../utility/isOAuthUser";
import updateCookieSameSite from "../../utility/cookies";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Loading from "../utils/Loading";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const RECENT = 60 * 60 * 12;

const useStyles = makeStyles(theme => ({
  mcLink: {
    ...theme.mcLink
  },
  saveButton: {
    ...theme.typography.mcButton
  }
}));

/**
 * Users return to this page after sign-in/sign-up from
 * Social Login.
 * Ask new users to agree to Privacy Policy and redirect
 */
const OAuthSignInReturnPage = props => {
  const { privacyPolicy, visitorAgreement } = props.links;
  const [consentGiven, setConsentGiven] = useState(false);
  const classes = useStyles();

  const userDataReady = () => {
    if (props.authProps.isAuthenticated && props.authProps.user?.attributes) {
      if (Object.keys(props.authProps.user.attributes).length) {
        updateCookieSameSite();
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // Determine if user signed up in past few hours
  const isRecentSignUp = user => {
    const attr = JSON.parse(user.attributes.identities)[0];
    const { dateCreated } = attr;
    const timeAgo = (Date.now() - dateCreated) / 1000;
    if (timeAgo < RECENT) {
      console.log("[OAUTH] recent signup", { user, timeAgo });
      return true;
    }

    console.log("[OAUTH] old signup", { user, timeAgo });
    return false;
  };

  const handleConsentChange = e => {
    setConsentGiven(e.target.checked === true);
  };

  const saveConsent = async () => {
    if (!props.authProps?.user) {
      return;
    }
    const { user } = props.authProps;
    const time = Date.now().toString();
    try {
      await API.put("extendedProfiles", `/${user.attributes.email}`, {
        body: {
          email: user.attributes.email,
          cognitoData: {
            custom_policyConsent: time
          }
        }
      });
    } catch (e) {
      console.log("[OAUTH] Error saving policy consent in EWP", { user }, e);
    }

    try {
      await Auth.updateUserAttributes(user, { "custom:policyConsent": time });
      user.storage.removeItem(user.userDataKey);
    } catch (e) {
      console.log(
        "[OAUTH] Error saving policy consent in Cognito",
        { user },
        e
      );
    }
    window.location.assign("/");
  };

  if (userDataReady()) {
    const { user } = props.authProps;

    if (!isOAuthUser(user)) {
      console.log("[OAUTH] Not OAuth User, redirecting.", { user });
      return <Redirect to="/" />;
    }

    if (!isRecentSignUp(user)) {
      console.log("[OAUTH] User is not recent sign up, redirecting.", {
        user
      });
      return <Redirect to="/" />;
    }

    if (user.attributes["custom:policyConsent"]) {
      console.log("[OAUTH] User has Policy Consent, redirecting.", { user });
      return <Redirect to="/" />;
    } else {
      console.log("[OAUTH] Require Policy Consent", { user });
      return (
        <Dialog open={true}>
          <DialogTitle>One more step...</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={<Checkbox required onClick={handleConsentChange} />}
              label={
                <Typography variant="subtitle2">
                  I have read and agree to the Cox Media Group&nbsp;
                  <Link
                    href={visitorAgreement}
                    className={classes.mcLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visitor Agreement
                  </Link>
                  {" and "}
                  <Link
                    className={classes.mcLink}
                    href={privacyPolicy}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </Link>
                  &nbsp;terms.
                </Typography>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!consentGiven}
              onClick={() => saveConsent(true)}
              variant="contained"
              className={classes.saveButton}
              color="primary"
            >
              I Agree
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  } else {
    console.log("[OAUTH] Waiting for user");
    return <Loading />;
  }
};

export default OAuthSignInReturnPage;
