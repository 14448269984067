/**
 * Set SameSite=None to allow sharing between Arc and MC Domains
 * Updated Amplify JS 5.x and 6.x can do this automatically.
 * TODO: update deps and remove this patch
 */
export default function updateCookieSameSite() {
  const cookies = document.cookie.split(";");
  const domain = window.location.hostname
    .split(".")
    .slice(-2)
    .join(".");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    const parts = cookie.split("=");
    if (parts.length === 2) {
      const name = parts[0];
      const value = parts[1];
      if (!/;\s*SameSite=None\s*/.test(cookie)) {
        document.cookie =
          name + "=" + value + `;domain=${domain};Path=/;SameSite=None;Secure`;
      }
    }
  }
}
