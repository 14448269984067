/**
 * Build filtered lists of alerts by the user's subs and a tag.
 *
 * Returns two lists:
 *
 *   - The given `allAlerts` list with the correct `status` value based on the
 *     provided `subbedIds`.
 *   - The user's active subbed alerts.
 *
 * @param {object[]} allAlerts List of alerts as objects
 * @param {string[]} subbedIds List of user's subbed alerts as ids
 * @param {string} tag Tag used to filter
 * @returns {[object[], object[]]}
 */
export const buildFilteredAlerts = (allAlerts, subbedIds, tag) => {
  // Filter and sort alerts.
  const filteredAlerts = allAlerts
    .filter(el => el.tags === tag)
    .sort((a, b) => a.display_name.localeCompare(b.display_name));

  // Build complete weather alerts list with correct status based on subscriptions.
  const alertsWithStatus = filteredAlerts.map(alert => ({
    ...alert,
    status: subbedIds.includes(alert.newsletterId) ? "active" : "inactive"
  }));

  // Pull out just the subbed alerts for easier display.
  const selectedAlerts = alertsWithStatus.filter(
    alert => alert.status === "active"
  );

  return [alertsWithStatus, selectedAlerts];
};
