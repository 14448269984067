import tinycolor from "tinycolor2";

export function setThemeColors(colors) {
  if (colors) {
    let palette = {
      primary: {},
      secondary: {},
      tertiary: {}
    };

    const primaryPalette = computeColors(colors.primary);
    const secondaryPalette = computeColors(colors.secondary);
    const tertiaryPalette = computeColors(colors.tertiary);

    palette.primary = Object.assign(
      {},
      ...primaryPalette.map(color => {
        return { [color.name]: color };
      })
    );

    palette.secondary = Object.assign(
      {},
      ...secondaryPalette.map(color => {
        return { [color.name]: color };
      })
    );

    palette.tertiary = Object.assign(
      {},
      ...tertiaryPalette.map(color => {
        return { [color.name]: color };
      })
    );

    return palette;
  }
}

function getColorObject(value, name) {
  var c = tinycolor(value);

  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight()
  };
}

export function computeColors(hex) {
  return [
    // these are the same functions that Material-UI uses to create dark, light, and contrastText
    // we're just replicating them here to set our custom colors
    getColorObject(tinycolor(hex).lighten(52), "50"),
    getColorObject(tinycolor(hex).lighten(37), "100"),
    getColorObject(tinycolor(hex).lighten(26), "200"),
    getColorObject(tinycolor(hex).lighten(12), "300"),
    getColorObject(tinycolor(hex).lighten(6), "400"),
    getColorObject(tinycolor(hex), "500"),
    getColorObject(tinycolor(hex).darken(6), "600"),
    getColorObject(tinycolor(hex).darken(12), "700"),
    getColorObject(tinycolor(hex).darken(18), "800"),
    getColorObject(tinycolor(hex).darken(24), "900"),
    getColorObject(
      tinycolor(hex)
        .lighten(50)
        .saturate(30),
      "A100"
    ),
    getColorObject(
      tinycolor(hex)
        .lighten(30)
        .saturate(30),
      "A200"
    ),
    getColorObject(
      tinycolor(hex)
        .lighten(10)
        .saturate(15),
      "A400"
    ),
    getColorObject(
      tinycolor(hex)
        .lighten(5)
        .saturate(5),
      "A700"
    )
  ];
}
