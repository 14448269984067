import React from "react";
import { Helmet } from "react-helmet";

/**
 * Sets the page's favicon dynamically.
 *
 * Note that this has a limitation (until SSR is implemented): bookmarked pages
 * will not show this favicon. Browsers appear to respect dynamically updated
 * titles in bookmarks, but not favicons.
 */
const FavIcon = ({ href }) => (
  <Helmet>
    <link rel="icon" href={href} />
  </Helmet>
);

export default FavIcon;
