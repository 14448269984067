import React from "react";
import { Redirect } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import DOMPurify from "dompurify";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  FormGroup,
  Button,
  Box,
  Typography,
  Paper
} from "@material-ui/core";
import Loading from "./utils/Loading";
import updateCookieSameSite from "../utility/cookies";
import ExtendedTextField from "../extended-material-ui/TextField";
import PhoneNumberField from "./fields/PhoneNumberField";
import NicknameOptions from "./Account/NicknameOptions";
import * as phoneNumberAPI from "./TextAlerts/api";
import { default as debugLog, LOG_TYPE } from "../utility/logger";
import {
  isValidZipCode,
  isValidNickName,
  checkUniqueNickname,
  isValidDate
} from "../utility/userProfileValidators";
import { currentExtendedProfileWrappedUser } from "../extended-amplify-auth/Authenticator";
import SnackbarAlert from "./utils/SnackbarAlert";

const alertSeverity = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warning"
};

// styles
const styles = theme => ({
  mcButton: {
    ...theme.typography.mcButton
  },
  paper: {
    ...theme.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

class UserAccount extends React.Component {
  formFieldHelperText = {
    given_name: "First name is required",
    family_name: "Last name is required",
    nickname:
      "Display name is required, and must have at least 4 letters/numbers",
    uniqueNickname: "Display name is not unique. Please try again",
    birthdate: "Birth date is either missing or has an invalid format",
    "custom:zip_code": "5-digit zip code is required"
  };

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      given_name: "",
      family_name: "",
      nickname: "",
      birthdate: "",
      "custom:street_line_1": "",
      "custom:street_line_2": "",
      "custom:city": "",
      "custom:state": "",
      "custom:zip_code": "",
      phoneNumberFieldProcessing: false,
      editStatus: { show: false, type: "", title: "", message: "", count: 0 }
    };

    this.nicknameOptions = [];
  }

  componentDidMount() {
    // Set initial state from authProps when user first signs in
    this.setInitialStateFromAuthProps();
  }

  async componentDidUpdate() {
    // Set initial state from authProps on other renders that do not
    // involve user sign-in
    this.setInitialStateFromAuthProps();
  }

  setInitialStateFromAuthProps = () => {
    const user = this.props.authProps && this.props.authProps.user;
    if (!this.stateInitializedWithAuthProps && user && user.attributes.email) {
      this.setState({
        email: user.attributes.email,
        given_name: user.attributes.given_name || "",
        family_name: user.attributes.family_name || "",
        nickname: user.attributes.nickname || "",
        birthdate: user.attributes.birthdate || "",
        "custom:street_line_1": user.attributes["custom:street_line_1"] || "",
        "custom:street_line_2": user.attributes["custom:street_line_2"] || "",
        "custom:city": user.attributes["custom:city"] || "",
        "custom:state": user.attributes["custom:state"] || "",
        "custom:zip_code": user.attributes["custom:zip_code"] || ""
      });
      this.stateInitializedWithAuthProps = true;
    }
  };

  // ALERT METHODS
  showAlert = (e, type, customMessage, messageCount) => {
    this.setState({
      editStatus: {
        show: true,
        type: type || alertSeverity.info,
        title: "Alert",
        message: customMessage || e.message || "",
        count: messageCount || 1
      }
    });
  };

  hideAlert = () => {
    this.setState({
      editStatus: {
        show: false,
        type: alertSeverity.info,
        title: "",
        message: "",
        count: 0
      }
    });
  };

  // PASSWORD METHODS
  updatePassword = async e => {
    e.preventDefault();

    const current_password = document.getElementById("current_password").value;
    const new_password = document.getElementById("new_password").value;
    const confirm_new_password = document.getElementById("confirm_new_password")
      .value;

    if (
      current_password === "" ||
      new_password === "" ||
      confirm_new_password === ""
    ) {
      this.showAlert(
        e,
        alertSeverity.info,
        "All password fields are required."
      );
      return;
    }

    if (new_password !== confirm_new_password) {
      this.showAlert(
        e,
        alertSeverity.error,
        "New password fields do not match."
      );
      return;
    }

    try {
      let result = await Auth.changePassword(
        this.props.authProps.user,
        current_password,
        new_password
      );
      if (result.toLowerCase() === "success") {
        this.showAlert(
          e,
          alertSeverity.success,
          "Password successfully changed."
        );
      }
    } catch (e) {
      switch (e.name) {
        case "InvalidParameterException":
          this.showAlert(
            e,
            alertSeverity.error,
            "Passwords must be a minimum of 6 characters"
          );
          break;
        case "NotAuthorizedException":
          this.showAlert(
            e,
            alertSeverity.error,
            "Current password is incorrect"
          );
          break;
        default:
          this.showAlert(e, alertSeverity.error);
      }
    }
  };

  // PHONE NUMBER METHODS

  /**
   * Validates the given phone number.
   *
   * @param {string} phoneNumber Number to be verified
   * @returns `true` if valid; `false` otherwise
   */
  validatePhoneNumber = async phoneNumber => {
    const email = this.props.authProps.user.attributes.email;

    this.setState({ phoneNumberFieldProcessing: true });

    let result;
    try {
      result = await phoneNumberAPI.verifyNumber(
        phoneNumber,
        email,
        this.props.site
      );
    } catch (e) {
      this.showAlert(
        e,
        alertSeverity.error,
        "We’re sorry. We have encountered an error, please try again."
      );
    }

    if (result && !result.isValid) {
      this.setState({ phoneNumberFieldProcessing: false });
      this.showAlert(
        null,
        alertSeverity.error,
        "Please correct your phone number and try again."
      );
    }

    return result && result.isValid;
  };

  /**
   * Deletes the user's phone number.
   *
   * Unsubscribes the number from all text alerts, and removes the number from
   * cognito and extended web profile.
   */
  deletePhoneNumber = async () => {
    const { authState, user } = this.props.authProps;
    const { phone_number: phoneNumber, email } = user.attributes;

    this.setState({ phoneNumberFieldProcessing: true });

    try {
      // Unsubscribe from mgage.
      await phoneNumberAPI.deleteNumber(phoneNumber, email, this.props.site);

      // Remove the number from cognito and extended profile.
      await phoneNumberAPI.updatePhoneNumber(user, "");

      // Update app's state with new user attributes.
      const updatedUser = await currentExtendedProfileWrappedUser();
      this.props.updateAuthProps(authState, updatedUser);

      this.setState({ phoneNumberFieldProcessing: false });
      this.showAlert(
        null,
        alertSeverity.success,
        "Your mobile number has been deleted. You are now unsubscribed from all text alerts."
      );
    } catch (e) {
      console.log(e);
      this.setState({ phoneNumberFieldProcessing: false });
      this.showAlert(
        e,
        alertSeverity.error,
        "Unable to delete your mobile number. Please try again."
      );
    }
  };

  /**
   * Saves the phone number.
   *
   * The number will be saved to cognito and extended web profile.
   *
   * @param {string} phoneNumber Can be assumed to be verified by `validatePhoneNumber`
   */
  savePhoneNumber = async phoneNumber => {
    const { authState, user } = this.props.authProps;

    try {
      // Update the user's profile.
      await phoneNumberAPI.updatePhoneNumber(user, phoneNumber);

      // Update App's state with new user attributes.
      const updatedUser = await currentExtendedProfileWrappedUser();
      this.props.updateAuthProps(authState, updatedUser);

      this.setState({ phoneNumberFieldProcessing: false });
      this.showAlert(
        null,
        alertSeverity.success,
        "Your mobile number has been saved."
      );
    } catch (e) {
      console.log(e);
      this.setState({ phoneNumberFieldProcessing: false });
      this.showAlert(
        e,
        alertSeverity.error,
        "Unable to save your mobile number. Please try again."
      );
    }
  };

  // PROFILE METHODS
  handleProfileChange = e => {
    if (this.state.editStatus.show) {
      this.hideAlert();
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  validateProfileData = async attributes => {
    let response = {
      status: "", // OK, ERROR
      errorMessages: []
    };

    // Add attribute checks as needed.
    // Never manually set response.status to "OK";
    // it is handled at end if no ERRORs.

    // given_name
    if (!attributes.given_name) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText.given_name);
    }

    // family_name
    if (!attributes.family_name) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText.family_name);
    }

    // nickname
    if (!isValidNickName(attributes.nickname)) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText.nickname);
    } else {
      const { isUnique, suggestions = [] } = await checkUniqueNickname(
        attributes.nickname,
        this.state.email
      );
      if (!isUnique) {
        response.status = "ERROR";
        response.errorMessages.push(this.formFieldHelperText.uniqueNickname);
        this.nicknameOptions = suggestions;
      } else {
        this.nicknameOptions = [];
      }
    }

    // birth_date
    if (!attributes.birthdate || !isValidDate(attributes.birthdate)) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText.birthdate);
    }

    // zip_code
    if (!isValidZipCode(attributes["custom:zip_code"])) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText["custom:zip_code"]);
    }

    if (response.status === "" && response.errorMessages.length === 0) {
      response.status = "OK";
    }
    return response;
  };

  updateProfile = async e => {
    e.preventDefault();
    const { user, authState } = this.props.authProps;

    // gather & sanitize profile data
    const newAttributes = {
      given_name: DOMPurify.sanitize(this.state.given_name.trim()),
      family_name: DOMPurify.sanitize(this.state.family_name.trim()),
      nickname: DOMPurify.sanitize(this.state.nickname.trim()),
      birthdate: DOMPurify.sanitize(this.state.birthdate.trim()),
      "custom:street_line_1": DOMPurify.sanitize(
        this.state["custom:street_line_1"].trim()
      ),
      "custom:street_line_2": DOMPurify.sanitize(
        this.state["custom:street_line_2"].trim()
      ),
      "custom:city": DOMPurify.sanitize(this.state["custom:city"].trim()),
      "custom:state": DOMPurify.sanitize(this.state["custom:state"].trim()),
      "custom:zip_code": DOMPurify.sanitize(
        this.state["custom:zip_code"].trim()
      )
    };

    // validate profile data
    const validationResponse = await this.validateProfileData(newAttributes);
    if (
      validationResponse.status !== "OK" &&
      validationResponse.errorMessages.length > 0
    ) {
      this.showAlert(
        new Error(validationResponse.errorMessages.join(" -- ")),
        alertSeverity.error,
        null,
        validationResponse.errorMessages.length
      );
      return;
    }

    let cognitoUpdateResult;
    // Update cognito profile
    try {
      cognitoUpdateResult = await Auth.updateUserAttributes(
        user,
        newAttributes
      );
      debugLog("Cognito Update:", LOG_TYPE.LOG, cognitoUpdateResult);
      user.storage.removeItem(user.userDataKey); // Force update Authenticator user data
    } catch (e) {
      this.showAlert(e, alertSeverity.error);
    }

    if (cognitoUpdateResult) {
      // Update extended profile
      try {
        let ewpUpdateResult = await API.put(
          "extendedProfiles",
          `/${user.attributes.email}`,
          {
            body: {
              email: user.attributes.email,
              cognitoData: Object.assign(
                { email: user.attributes.email },
                newAttributes
              )
            }
          }
        );
        debugLog("Extended Web Profile Update:", LOG_TYPE.LOG, ewpUpdateResult);

        // Update App's state with new user attributes.
        const updatedUser = await currentExtendedProfileWrappedUser();
        this.props.updateAuthProps(authState, updatedUser);

        this.showAlert(e, alertSeverity.success, "Profile updated.");
      } catch (e) {
        this.showAlert(e, alertSeverity.error);
      }
    }
    updateCookieSameSite();
  };

  updatePasswordWithEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.updatePassword(e);
    }
  };

  updateProfileWithEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.updateProfile(e);
    }
  };

  handleNicknameOptionChange = e => {
    const { value } = e.target;
    this.setState({ nickname: value });
  };

  render() {
    const {
      isAuthenticated,
      authState,
      user,
      tenantConfig
    } = this.props.authProps;
    const { classes } = this.props;

    if (authState) {
      if (isAuthenticated && user) {
        const navItems = tenantConfig.ui.menuConfig.nav;
        const isTextAlerts = navItems.includes("Text Alerts");
        return (
          <Container maxWidth="sm">
            <Typography variant="h2" gutterBottom>
              Edit Profile
            </Typography>

            <form onSubmit={this.updateProfile}>
              <Paper className={classes.paper}>
                <Typography variant="h3" gutterBottom>
                  Update Profile
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Fields with an asterisk (*) are required.
                </Typography>

                <FormGroup>
                  <ExtendedTextField
                    id="given_name"
                    type="text"
                    label="First Name"
                    value={this.state.given_name}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="family_name"
                    type="text"
                    label="Last Name"
                    value={this.state.family_name}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="nickname"
                    type="text"
                    label="Display Name"
                    value={this.state.nickname}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                    required
                  />
                  {this.nicknameOptions.length > 0 && (
                    <NicknameOptions
                      handleChange={this.handleNicknameOptionChange}
                      options={this.nicknameOptions}
                    />
                  )}
                  <ExtendedTextField
                    id="birthdate"
                    type="date"
                    label="Birth Date"
                    placeholder="yyyy-mm-dd" /* for browsers that don't support the date type */
                    InputLabelProps={{ shrink: true }}
                    value={this.state.birthdate}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="custom:street_line_1"
                    type="text"
                    label="Street Address 1"
                    value={this.state["custom:street_line_1"]}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                  />
                  <ExtendedTextField
                    id="custom:street_line_2"
                    type="text"
                    label="Street Address 2"
                    value={this.state["custom:street_line_2"]}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                  />
                  <ExtendedTextField
                    id="custom:city"
                    type="text"
                    label="City"
                    value={this.state["custom:city"]}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                  />
                  <ExtendedTextField
                    id="custom:state"
                    type="text"
                    label="State"
                    value={this.state["custom:state"]}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                  />
                  <ExtendedTextField
                    id="custom:zip_code"
                    type="text"
                    label="Zip Code (5 digits)"
                    inputProps={{ maxLength: 10 }}
                    value={this.state["custom:zip_code"]}
                    onChange={this.handleProfileChange}
                    onKeyPress={this.updateProfileWithEnter}
                    color="secondary"
                    required
                  />
                  <Box my={2}>
                    <Button
                      className={classes.mcButton}
                      variant="contained"
                      fullWidth
                      onClick={this.updateProfile}
                    >
                      Update Profile
                    </Button>
                  </Box>
                </FormGroup>
              </Paper>
            </form>

            {isTextAlerts && (
              <Paper className={classes.paper}>
                <Typography variant="h3" gutterBottom>
                  Mobile Number
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Enter your mobile number to receive text alerts. *
                </Typography>

                <Box mb={2}>
                  <PhoneNumberField
                    isProcessing={this.state.phoneNumberFieldProcessing}
                    phoneNumber={user.attributes.phone_number}
                    onValidate={this.validatePhoneNumber}
                    onDelete={this.deletePhoneNumber}
                    onAdd={this.savePhoneNumber}
                  />
                </Box>
              </Paper>
            )}

            <form onSubmit={this.updatePassword}>
              <Paper className={classes.paper}>
                <Typography variant="h3" gutterBottom>
                  Change Password
                </Typography>

                <p>
                  {user.attributes ? user.attributes.email : this.state.email}
                </p>
                <FormGroup>
                  <ExtendedTextField
                    id="current_password"
                    autoComplete="current-password"
                    type="password"
                    label="Current Password"
                    onKeyPress={this.updatePasswordWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="new_password"
                    type="password"
                    autoComplete="new-password"
                    label="New Password"
                    onKeyPress={this.updatePasswordWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="confirm_new_password"
                    type="password"
                    autoComplete="new-password"
                    label="Confirm New Password"
                    onKeyPress={this.updatePasswordWithEnter}
                    color="secondary"
                    required
                  />
                  <Box my={2}>
                    <Button
                      className={classes.mcButton}
                      variant="contained"
                      fullWidth
                      onClick={this.updatePassword}
                    >
                      Update Password
                    </Button>
                  </Box>
                </FormGroup>
              </Paper>
            </form>

            <SnackbarAlert
              show={this.state.editStatus.show}
              hide={this.hideAlert}
              duration={3000 * this.state.editStatus.count}
              severity={this.state.editStatus.type}
              message={this.state.editStatus.message}
            />
          </Container>
        );
      } else {
        return <Redirect to="/sign-in" />;
      }
    } else {
      return <Loading />;
    }
  }
}

export default withStyles(styles, { withTheme: true })(UserAccount);
