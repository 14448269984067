import React from "react";
import { Container, Box, Tab, Tabs } from "@material-ui/core";
class AuthTabs extends React.Component {

  componentDidMount = async () => {
    if ( window.location.pathname === "/create-account" 
      && this.props.authState !== 'signUp' 
      && this.props.authState !== 'confirmSignUp'
    ) {
      this.props.onTabChange('signUp');
    }
  }

  render() {
    const tabValueMapping = {
      signIn: "signIn",
      forgotPassword: "signIn",
      signUp: "signUp",
      confirmSignUp: "signUp"
    };

    const tabUrlMapping = {
      signIn: "sign-in",
      signUp: "create-account"
    }

    return (
      <>
        <Tabs
          value={tabValueMapping[this.props.authState]}
          onChange={(e, tabValue) => {
            this.props.onTabChange(tabValue);
            window.history.replaceState(
              {},
              window.document.title,
              tabUrlMapping[tabValue] 
            );
          }}
          indicatorColor="primary"
          centered
        >
          <Tab value="signIn" label="Log In" to="/sign-in"/>
          <Tab value="signUp" label="Create Account" to="/create-account"/>
        </Tabs>

        <Container maxWidth="xs">
          <Box my={2}>{this.props.children}</Box>
        </Container>
      </>
    );
  }
}

export default AuthTabs;
