import React from "react";
import Loading from "../utils/Loading";
import { Auth } from "aws-amplify";
import Box from "@material-ui/core/Box";
import log from "../../utility/logger";
import { handleSsoPostSignOutAction } from "../../extended-amplify-auth/handleSsoPostActions";

/**
 * Sign Out Component Page intended to be used by SDK. Uses queryStringParameters
 * to determine if it needs to sign out and then redirect or close the window.
 */
const SignOutPage = props => {
  if (props.authProps.authState) {
    if (props.authProps.isAuthenticated && props.authProps.user) {
      try {
        Auth.signOut();
      } catch (e) {
        log("Already signed out or error", 1, e);
      }
    }

    // Redirect, Close, or nothing
    handleSsoPostSignOutAction();

    return (
      <Box display="flex" m={5}>
        <p>You are logged out</p>
      </Box>
    );
  } else {
    return <Loading />;
  }
};

export default SignOutPage;
