import React from "react";
import { Typography } from "@material-ui/core";

const NewsletterHeader = () => {
  return (
    <>
      <Typography variant="h2">Newsletters</Typography>
      <Typography variant="body1" gutterBottom>
        Subscribe to our free newsletters here.
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        *Unsubscribing from newsletters may take up to 48 hours to process.
      </Typography>
    </>
  );
};

export default NewsletterHeader;
