/**
 * The SDK will link the user to /sign-in or /account/logout and
 * after that action is complete, this function will redirect the
 * user back to the tenant if requested.
 *
 * TODO: Rework SignIn Handler to avoid the explicit wait or show loading state
 */
import * as qs from "query-string";

export const handleSsoPostSignInAction = async authEvent => {
  if (authEvent.payload.event === "signIn") {
    let params = window.location.search;
    const q = qs.parse(params);

    if (
      q.action === "login" &&
      q.postAction === "close" &&
      !window.location.pathname.includes("oauth")
    ) {
      if (q.referrer) {
        setTimeout(() => {
          window.close();
        }, 250);
      }
    } else if (q.action === "login" && q.postAction === "redirect") {
      if (q.referrer) {
        setTimeout(() => {
          window.location.replace(q.referrer);
        });
      }
    }
  }
};

// Call after `authProps.authState` is known
export const handleSsoPostSignOutAction = () => {
  const q = qs.parse(window.location.search);
  if (q.action === "logout" && q.postAction === "close") {
    if (q.referrer) {
      // This timeout gives user time to read the "you are signed out" message
      setTimeout(() => window.close(), 1000);
    }
  } else if (q.action === "logout" && q.postAction === "redirect") {
    if (q.referrer) {
      setTimeout(() => {
        window.location.replace(q.referrer);
      });
    }
  }
};
