import {
  Button,
  IconButton,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import DeletePhoneNumberDialog from "./DeletePhoneNumberDialog";
import ExtendedTextField from "../../../extended-material-ui/TextField";

const useStyles = makeStyles(theme => ({
  progressButtonWrapper: {
    position: "relative",
    marginTop: theme.spacing(1)
  },
  saveButton: {
    ...theme.typography.mcButton
  },
  saveProgressIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  deleteProgressIndicator: {
    marginRight: theme.spacing(1)
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    marginTop: "-5px"
  }
}));

/**
 * Allows a user to add/delete a phone number.
 */
const PhoneNumberField = ({
  phoneNumber,
  isProcessing,
  onValidate,
  onDelete,
  onAdd
}) => {
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [numberInput, setNumberInput] = useState(phoneNumber || "");
  const [isValid, setIsValid] = useState(true);

  const resetForm = () => {
    setIsValid(true);
    setNumberInput("");
  };

  /**
   * Handles the DeletePhoneNumberDialog onClose.
   *
   * @param {boolean} shouldDelete `true` if user clicked "Delete"; false if "Cancel" clicked
   */
  const handleDeleteDialogClose = async shouldDelete => {
    setDeleteDialogOpen(false);
    resetForm();

    if (onDelete && shouldDelete) {
      onDelete();
    }
  };

  /**
   * Handles validation and onAdd callback.
   */
  const handleSaveClick = async () => {
    // Trim and remove non-digit characters.
    const value = numberInput.trim().replace(/[^\d]*/g, "");

    if (!onValidate) {
      // No validation.
      resetForm();

      if (onAdd) {
        onAdd(value);
      }
    } else if (await onValidate(value)) {
      resetForm();

      if (onAdd) {
        onAdd(value);
      }
    } else {
      setIsValid(false);
    }
  };

  return (
    <div>
      {phoneNumber ? (
        <>
          {isProcessing ? (
            <>
              <CircularProgress
                size={24}
                className={classes.deleteProgressIndicator}
              />
              <Typography component="span" variant="body1">
                Deleting...
              </Typography>
            </>
          ) : (
            <>
              <Typography component="span" variant="body1">
                {phoneNumber.replace(/^\+/, "")}
              </Typography>
              <IconButton
                aria-label="delete"
                onClick={() => setDeleteDialogOpen(true)}
                className={classes.deleteButton}
                title="Delete"
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ) : (
        <>
          <ExtendedTextField
            margin="dense"
            fullWidth
            type="tel"
            label="Mobile Phone"
            value={numberInput}
            onChange={e => setNumberInput(e.target.value)}
            onKeyPress={e => e.key === "Enter" && handleSaveClick()}
            disabled={isProcessing}
            error={!isValid}
            helperText={
              isValid ? "Please enter numbers only" : "Invalid phone number."
            }
            color="secondary"
          />
          <div className={classes.progressButtonWrapper}>
            <Button
              className={classes.saveButton}
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
              disabled={!numberInput || isProcessing}
              fullWidth
            >
              Save
            </Button>
            {isProcessing && (
              <CircularProgress
                size={24}
                className={classes.saveProgressIndicator}
              />
            )}
          </div>
        </>
      )}

      <DeletePhoneNumberDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
      />
    </div>
  );
};

export default PhoneNumberField;
