import React from "react";
import { SignIn } from "aws-amplify-react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormGroup,
  Box,
  Divider,
  Link,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ExtendedOAuthButtons from "./OAuthButtons";
import AuthTabs from "./AuthTabs";
import ExtendedTextField from "../extended-material-ui/TextField";

import { Auth, Logger } from "aws-amplify";

/* Authenticator components can be extended to allow UI customization
 * and in some cases, minor functionality and flow customization.
 *
 * The main method that needs to be overridden for most UI customization is showComponent()
 */

const logger = new Logger("SignIn");

// styles
const styles = theme => ({
  mcButton: {
    ...theme.typography.mcButton
  },
  mcLink: {
    ...theme.mcLink
  }
});

class ExtendedSignInThemed extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      extSignInThemedSignInError: false,
      extSignInThemedSignInErrorMessage: ""
    };
  }

  checkContact(user) {
    /* Override SignIn's checkContact() method to skip the "verifyContact" state and
     * go straight to "signedIn":
     *
     * checkContact:
     * https:github.com/aws-amplify/amplify-js/blob/7dbc5b5/packages/aws-amplify-react/src/Auth/SignIn.tsx#L62-L76
     *
     * setTimeout with no delay here allows the state change to happen asynchronously
     * to allow other component functions to complete first.
     */
    setTimeout(() => this.changeState("signedIn", user));
  }

  hideAlert = () => {
    this.setState({
      extSignInThemedSignInError: false,
      extSignInThemedSignInErrorMessage: ""
    });
  };

  signIn = async event => {
    if (event) {
      event.preventDefault();
    }

    this.inputs = this.inputs || {};
    let username = (this.inputs["username"] || "").trim();
    const password = this.inputs["password"] || "";

    if (!username || !password) {
      this.setState({
        extSignInThemedSignInError: true,
        extSignInThemedSignInErrorMessage:
          "Please enter your username and password."
      });
      return;
    }

    this.inputs["username"] = username; // trimmed username

    username = this.getUsernameFromInput() || "";

    this.setState({ loading: true });
    try {
      const user = await Auth.signIn(username, password);
      logger.debug(user);
      if (user.challengeName) {
        logger.debug("confirm user with " + user.challengeName);
        this.changeState("confirmSignIn", user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.debug("require new password", user.challengeParam);
        this.changeState("requireNewPassword", user);
      } else if (user.challengeName === "MFA_SETUP") {
        logger.debug("TOTP setup", user.challengeParam);
        this.changeState("TOTPSetup", user);
      } else if (
        user.challengeName === "CUSTOM_CHALLENGE" &&
        user.challengeParam &&
        user.challengeParam.trigger === "true"
      ) {
        logger.debug("custom challenge", user.challengeParam);
        this.changeState("customConfirmSignIn", user);
      } else {
        this.checkContact(user);
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        logger.debug("the user is not confirmed");
        this.changeState("confirmSignUp", { username });
      } else if (err.code === "PasswordResetRequiredException") {
        logger.debug("the user requires a new password");
        this.changeState("forgotPassword", { username });
      } else if (
        err.code === "NotAuthorizedException" ||
        err.code === "UserNotFoundException"
      ) {
        this.error(err);
      } else {
        this.error(err);
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  signInWithEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.signIn();
    }
  };

  showComponent() {
    return (
      <AuthTabs
        authState={this.props.authState}
        onTabChange={tabValue => super.changeState(tabValue)}
      >
        {this.props.authState === "signIn" && (
          <>
            <Box mb={2}>
              <FormGroup>
                <form>
                  <ExtendedTextField
                    id="username"
                    key="username"
                    name="username"
                    type="text"
                    label="Email Address"
                    onChange={this.handleInputChange}
                    onKeyPress={this.signInWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    label="Password"
                    onChange={this.handleInputChange}
                    onKeyPress={this.signInWithEnter}
                    color="secondary"
                    required
                  />
                  <Link
                    className={this.props.classes.mcLink}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      super.changeState("forgotPassword");
                    }}
                  >
                    Forgot your password?
                  </Link>
                  <Box my={2}>
                    <Button
                      className={this.props.classes.mcButton}
                      size="large"
                      variant="contained"
                      fullWidth
                      onClick={this.signIn}
                    >
                      Log In
                    </Button>
                  </Box>
                </form>
              </FormGroup>
            </Box>
            <Snackbar
              open={this.state.extSignInThemedSignInError}
              autoHideDuration={3000}
              onClose={this.hideAlert}
            >
              <Alert onClose={this.hideAlert} severity="error">
                {this.state.extSignInThemedSignInErrorMessage}
              </Alert>
            </Snackbar>
            <Divider />
            <div
              style={{
                padding: "0 25px",
                color: "gray",
                backgroundColor: "white",
                margin: "-10px auto",
                width: "22px"
              }}
            >
              OR
            </div>
            <ExtendedOAuthButtons />
          </>
        )}
      </AuthTabs>
    );
  }
}

export const ExtendedSignIn = withStyles(styles, { withTheme: true })(
  ExtendedSignInThemed
);
