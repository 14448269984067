import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  deleteButton: {
    borderColor: "#dc004e",
    color: "#dc004e"
  }
}));

/**
 * Displays a "delete mobile number" confirmation dialog.
 *
 * `onClose` will be called with `true` if the user has clicked "Delete" and
 * `false` if the user has clicked "Cancel".
 */
const DeletePhoneNumberDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Delete mobile number</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this mobile number? You will be
          opted-out of all text alerts.{" "}
          <strong>This action can not be undone.</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={() => onClose(true)} className={classes.deleteButton}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePhoneNumberDialog;
