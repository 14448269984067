import React from "react";
import { Redirect } from "react-router-dom";

const SignInPage = props => {
  if (props.authProps.isAuthenticated && props.authProps.user) {
    return <Redirect to="/" />;
  } else {
    return null;
  }
};

export default SignInPage;
