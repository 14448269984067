import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

/**
 * Scrolls to the top of the page on location change.
 *
 * See https://reacttraining.com/react-router/web/guides/scroll-restoration for
 * more information.
 *
 * If this ends up being too aggressive, we can use the `ScrollToTopOnMount`
 * example and place it only on the routes we want to scroll to the top.
 */
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <></>;
};

export default ScrollToTop;
