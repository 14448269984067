/**
 * Determine the station's Tenant Config URL, pull it from the Tenant Config API
 * and return it back to App.js.
 *
 * NOTE: this operation occurs first and must be fast because it's blocking everything else
 */
import { API } from "aws-amplify";
import domainToTenantMapping from "./domainToTenantMapping";

/**
 * Convert the inputted hostname into a Tenant Config Id.
 * In non-prod, allow overrides with the queryStringParameter `tenant`.
 * Return a default for localhost.
 *
 * See `hostnameToStation` unit tests for examples
 */
export const hostnameToStation = (hostname, queryparams = "") => {
  const domainParts = hostname.split(".");
  const firstDomainPart = domainParts[0];

  // Prod
  // TODO: remove 'membercenter-cognito' after launch
  if (
    firstDomainPart === "membercenter" ||
    firstDomainPart === "membercenter-cognito"
  ) {
    return domainToTenantMapping[domainParts[1]];
  }

  // Non-prod
  const nonProdDomains = [
    "localhost",
    "d3ujgevdqj5ekp",
    "membercenter-dev",
    "membercenter-staging"
  ];
  if (nonProdDomains.includes(firstDomainPart)) {
    // Browser support limited but we don't care for non-prod
    if (queryparams && URLSearchParams) {
      const queryParams = new URLSearchParams(queryparams);
      if (queryParams.has("tenant")) {
        return queryParams.get("tenant");
      }
    } else if (queryparams && !URLSearchParams) {
      console.error(
        "TenantConfig: browser does not support tenant override feature"
      );
    }

    if (hostname === "localhost" || firstDomainPart === "d3ujgevdqj5ekp") {
      return "wsb-tv";
    } else {
      return domainToTenantMapping[domainParts[1]];
    }
  }
};

// Return the Tenant Config Endpoint Response for this Station
export const getTenantConfig = async station => {
  const hostname = window.location.hostname;
  const queryparams = window.location.search;
  const stationId = station || hostnameToStation(hostname, queryparams);

  const tenantConfig = await API.get("tenantConfigs", "", {
    queryStringParameters: { station: stationId }
  }).catch(e => {
    console.log("TenantConfig: error fetching tenant config", station, e);
  });

  return tenantConfig;
};
