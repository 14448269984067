let config;

const oAuthRedirectSignInUrl = window.location.origin + "/oauth/";
const oAuthRedirectSignOutUrl = window.location.origin + "/";

switch (process.env.REACT_APP_DEPLOY_ENV) {
  case "prod":
    config = {
      cognito: {
        region: "us-east-1",
        userPoolId: "us-east-1_lGMZVYltr",
        userPoolWebClientId: "3cbusfei41th27vvf38ee8thgr",
        cookieStorage: {
          domain: window.location.hostname
            .split(".")
            .slice(-2)
            .join("."),
          path: "/",
          sameSite: "none",
          expires: 30,
          secure: true
        }
      },
      oauthConfig: {
        domain:
          "member-center-serverless-frontend-prod.auth.us-east-1.amazoncognito.com",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: oAuthRedirectSignInUrl,
        redirectSignOut: oAuthRedirectSignOutUrl,
        responseType: "code"
      },
      apiGateway: {
        URL: "https://member-center-api.cmg.com/api/v1",
        APIKEY: process.env.REACT_APP_MC_API_KEY
      }
    };
    break;
  case "staging":
    config = {
      cognito: {
        region: "us-east-1",
        userPoolId: "us-east-1_6NvgGLCzG",
        userPoolWebClientId: "u711ui5npvoi0ko0c3po6udci",
        cookieStorage: {
          domain: window.location.hostname
            .split(".")
            .slice(-2)
            .join("."),
          path: "/",
          sameSite: "none",
          expires: 30,
          secure: true
        }
      },
      oauthConfig: {
        domain:
          "member-center-serverless-frontend-staging.auth.us-east-1.amazoncognito.com",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: oAuthRedirectSignInUrl,
        redirectSignOut: oAuthRedirectSignOutUrl,
        responseType: "code"
      },
      apiGateway: {
        URL: "https://member-center-api-staging.cmg.com/api/v1",
        APIKEY: process.env.REACT_APP_MC_API_KEY
      }
    };
    break;
  case "dev":
    config = {
      cognito: {
        region: "us-east-1",
        userPoolId: "us-east-1_LnneevA2V",
        userPoolWebClientId: "4tvpmrq43n1a1dqqlgcf9ds9pn"
      },
      oauthConfig: {
        domain:
          "member-center-serverless-frontend-development.auth.us-east-1.amazoncognito.com",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: oAuthRedirectSignInUrl,
        redirectSignOut: oAuthRedirectSignOutUrl,
        responseType: "code"
      },
      apiGateway: {
        URL: "https://member-center-api-dev.cmg-sandbox.com/api/v1",
        APIKEY: process.env.REACT_APP_MC_API_KEY
      }
    };
    break;
  case "LOCAL":
    config = {
      cognito: {
        region: "us-east-1",
        userPoolId: "us-east-1_6NvgGLCzG",
        userPoolWebClientId: "u711ui5npvoi0ko0c3po6udci",
        cookieStorage: {
          domain: window.location.hostname
            .split(".")
            .slice(-2)
            .join("."),
          path: "/",
          expires: 30,
          sameSite: "lax",
          secure: false
        }
      },
      oauthConfig: {
        domain:
          "member-center-serverless-frontend-staging.auth.us-east-1.amazoncognito.com",
        scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: oAuthRedirectSignInUrl,
        redirectSignOut: oAuthRedirectSignOutUrl,
        responseType: "code"
      },
      apiGateway: {
        URL: "http://localhost:3000/api/v1",
        APIKEY: "api-key-for-local-serverless-offline"
      }
    };
    break;
  default:
    config = {};
    break;
}

export default config;
