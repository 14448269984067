import React from "react";
import { Redirect } from "react-router-dom";

const SignUpPage = props => {

  if (props.authProps.isAuthenticated && props.authProps.user) {
    return <Redirect to="/" />;
  } else {
    return null;
  }
};

export default SignUpPage;