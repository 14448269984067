import React from "react";
import { ConfirmSignUp } from "aws-amplify-react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Link, TextField } from "@material-ui/core";
import AuthTabs from "./AuthTabs";

/* Authenticator components can be extended to allow UI customization
 * and in some cases, minor functionality and flow customization.
 *
 * The main method that needs to be overridden for most UI customization is showComponent()
 */

// styles are a placeholder for now
const styles = theme => ({
  mcLink: {
    ...theme.mcLink
  }
});

class ExtendedConfirmSignUpThemed extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
  }

  showComponent() {
    const authData = this.props.authData;
    let emailDisplay = "",
      email = "";
    if (authData) {
      if (typeof authData === "string") {
        email = authData;
      } else if (authData.username) {
        email = authData.username;
      }
    }

    if (email) {
      emailDisplay = (
        <TextField
          id="email"
          type="text"
          margin="none"
          size="small"
          variant="outlined"
          value={email}
          disabled
          fullWidth
        />
      );
    }

    return (
      <AuthTabs
        authState={this.props.authState}
        onTabChange={tabValue => super.changeState(tabValue)}
      >
        {this.props.authState === "confirmSignUp" && (
          <>
            <Box mb={2}>
              <p>We have sent you a verification email {email ? "at:" : ""}</p>
              {emailDisplay}
              <p>
                Please check your email and click on the link to activate your
                profile.
              </p>
              <p>
                If you do not receive the verification message within a few
                minutes of signing up, please check your Spam or Junk folder.
              </p>
              <Link
                className={this.props.classes.mcLink}
                href="/sign-in"
                onClick={e => {
                  e.preventDefault();
                  super.changeState("signIn");
                  window.history.replaceState(
                    {},
                    window.document.title,
                    'sign-in'
                  );
                }}
              >
                Back to Log In
              </Link>
            </Box>
          </>
        )}
      </AuthTabs>
    );
  }
}

export const ExtendedConfirmSignUp = withStyles(styles, { withTheme: true })(
  ExtendedConfirmSignUpThemed
);
