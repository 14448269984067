import { API, Auth } from "aws-amplify";
import DOMPurify from "dompurify";

/**
 * Fetches the user's text alert subscriptions from the API.
 *
 * @param {string} phoneNumber
 * @param {string} email
 * @param {string} station
 */
export const fetchTextAlerts = async (phoneNumber, email, station) => {
  const phone = phoneNumber.replace(/^\+/, "");
  const textAlerts = await API.get("textalerts", `/${phone}`, {
    queryStringParameters: {
      station,
      phone,
      email
    }
  });

  return textAlerts;
};

/**
 * Sends the API request to sub or unsub from a text alert.
 *
 * @param {string} phoneNumber
 * @param {string} email
 * @param {string} station
 * @param {string} alertId ID for alert
 * @param {boolean} status True to perform a sub request; false to perform an unsub
 */
export const setAlertSub = async (
  phoneNumber,
  email,
  station,
  alertId,
  status
) => {
  const phone = phoneNumber.replace(/^\+/, "");

  if (status) {
    // Sub to alert.
    const response = await API.post("textalerts", `/${phone}`, {
      queryStringParameters: {
        station,
        email,
        listId: alertId
      }
    });

    return response;
  } else {
    // Unsub from alert.
    const response = await API.del("textalerts", `/${phone}`, {
      queryStringParameters: {
        station,
        email,
        listId: alertId
      }
    });

    return response;
  }
};

/**
 * Opts the given phone number out of all text alert subscriptions.
 *
 * This does not remove the number from the user's profile.
 *
 * @param {string} phoneNumber
 * @param {string} email
 * @param {string} station
 */
export const deleteNumber = async (phoneNumber, email, station) => {
  const phone = phoneNumber.replace(/^\+/, "");
  const response = API.del("textalerts", `/clearall/${phone}`, {
    queryStringParameters: {
      station,
      email
    }
  });

  return response;
};

/**
 * Verifies a phone number is valid.
 *
 * Example response format on invalid phone number:
 *
 *     {
 *       result: {
 *         name: "InvalidPhoneNumberError",
 *         message: "An invalid mobile phone number was provided"
 *       },
 *       success: true,
 *       isValid: false
 *     }
 *
 * Example response format on valid valid phone number:
 *
 *     { success: true, isValid: true }
 *
 * @param {string} phoneNumber
 * @param {string} email
 * @param {string} station
 */
export const verifyNumber = async (phoneNumber, email, station) => {
  if (!phoneNumber) {
    return { isValid: false };
  }

  const phone = phoneNumber.replace(/^\+/, "");
  const response = await API.get("textalerts", `/verify/${phone}`, {
    queryStringParameters: {
      station,
      email
    }
  });

  // It seems a number is valid if the `result` key is missing.
  return { ...response, isValid: !("result" in response) };
};

/**
 * Handles updating the phone number in Cognito and the extended web profile.
 *
 * This is NOT atomic. It's possible that one update will fail and leave the
 * auth user's attributes out of sync with their extended web profile.
 *
 * @param {object} user The Amplify user
 * @param {string} phoneNumber May be the empty string to "delete" the number
 * @returns {Promise<string>} The cleaned number
 */
export const updatePhoneNumber = async (user, phoneNumber) => {
  // Amplify wants the number to start with "+".
  const email = user.attributes.email;
  const cleanedNumber = DOMPurify.sanitize(
    phoneNumber === "" || phoneNumber.startsWith("+")
      ? phoneNumber
      : `+${phoneNumber}`
  );

  // Update user attributes.
  const updateCognito = async () =>
    await Auth.updateUserAttributes(user, {
      phone_number: cleanedNumber
    });

  // Update the user's extended profile.
  const updateProfile = async () =>
    await API.put("extendedProfiles", `/${email}`, {
      body: {
        email,
        cognitoData: {
          phone_number: cleanedNumber
        }
      }
    });

  const updateCognitoResult = await updateCognito();
  if (updateCognitoResult !== "SUCCESS") {
    throw new Error("Unable to update Cognito profile.");
  }

  // Force update Authenticator user data.
  user.storage.removeItem(user.userDataKey);

  const updateProfileResult = await updateProfile();
  if (!updateProfileResult || updateProfileResult.status !== "success") {
    throw new Error("Unable to update extended web profile.");
  }
};
