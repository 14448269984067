/*
 * When a social auth user signs out, the page immediately redirects to the
 * social site's oauth redirect URL, which prevents Member Center from
 * performing any post "oAuthSignOut" cleanup.
 *
 * This module provides a way to do any necessary post "oAuthSignOut" cleanup:
 *
 * 1. After Amplify triggers an "oAuthSignOut" event, the preSignout method
 *    drops a storage "reminder".
 *
 * 2. Once the site returns from the social site's oauth redirect URL, the
 *    postSignout method consumes the storage "reminder" and performs
 *    any post "oAuthSignOut" actions in the callback.
 */

const getOAuthSignOutTracker = storageKey => {
  return {
    preSignoutSetup: () => {
      window.addEventListener("beforeunload", () => {
        window.localStorage.setItem(storageKey, true);
      });
    },
    postSignoutCleanup: () => {
      if (window.localStorage.getItem(storageKey)) {
        window.localStorage.removeItem(storageKey);
        return true;
      }
      return false;
    }
  };
};

export const oAuthSignOutSso = getOAuthSignOutTracker("sso_oAuthSignOut");
export const oAuthSignOutRedirect = getOAuthSignOutTracker(
  "redirect_oAuthSignOut"
);
