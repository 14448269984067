import React from "react";
import { API } from "aws-amplify";
import * as qs from "query-string";
import { Box, Link, Typography } from "@material-ui/core";
import Loading from "../utils/Loading";
import { withStyles } from "@material-ui/core/styles";

/**
 * Users are directed to this page from email. Use API to confirm account and
 * then direct them to sign-in or show error messages.
 */

// styles are a placeholder for now
const styles = theme => ({
  mcLink: {
    ...theme.mcLink
  }
});

class ConfirmAccountPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmationLoading: true,
      confirmed: false,
      errorMsg: ""
    };
  }

  async componentDidMount() {
    const q = qs.parse(this.props.location.search);
    try {
      const response = await API.post("accounts", "/confirm", {
        body: q
      });
      window.history.replaceState(
        {},
        window.document.title,
        this.props.location.pathname
      );

      if (response.code === "CodeResent") {
        // Ideally this should be sent as a 400 response.
        // But the response message for a 400 error is currently not received in the browser.
        // AWS support ticket has been opened to look into this.
        // The 200 response is a temporary workaround
        this.setState({
          confirmationLoading: false,
          confirmed: false,
          errorMsg:
            "Your verification link has expired. We have sent you a new verification email."
        });
      } else {
        this.setState({
          confirmationLoading: false,
          confirmed: true,
          errorMsg: ""
        });
      }
    } catch (err) {
      if (err.code === "ExpiredCodeException") {
        this.setState({
          confirmationLoading: false,
          confirmed: false,
          errorMsg:
            "Your verification link has expired. We have sent you a new verification email."
        });
      } else {
        this.setState({
          confirmationLoading: false,
          confirmed: false,
          errorMsg:
            "There was an error confirming your account. Please try again."
        });
      }
    }
  }

  render() {
    if (this.props.authProps.authState) {
      let titleMessage;

      if (
        (this.props.authProps.isAuthenticated && this.props.authProps.user) ||
        (!this.state.confirmationLoading && this.state.confirmed)
      ) {
        titleMessage = "Your account is confirmed.";
      } else if (!this.state.confirmationLoading && this.state.errorMsg) {
        titleMessage = this.state.errorMsg;
      } else if (this.state.confirmationLoading) {
        return (
          <Box display="flex" m={5}>
            <h2>Confirming your account....</h2>
          </Box>
        );
      }

      return (
        <Box display="flex" m={5}>
          <div>
            <Typography variant="h2">{titleMessage}</Typography>
            <Typography variant="body1">
              Please{" "}
              <Link className={this.props.classes.mcLink} href="/sign-in">
                Log in
              </Link>
              .
            </Typography>
          </div>
        </Box>
      );
    } else {
      return <Loading />;
    }
  }
}

export default withStyles(styles, { withTheme: true })(ConfirmAccountPage);
