import React from "react";
import { Auth, Logger } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormGroup,
  Box,
  Divider,
  Link,
  TextField,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AuthTabs from "./AuthTabs";
import ExtendedTextField from "../extended-material-ui/TextField";

/* Authenticator components can be extended to allow UI customization
 * and in some cases, minor functionality and flow customization.
 *
 * The main method that needs to be overridden for most UI customization is showComponent()
 */

const logger = new Logger("ForgotPassword");

// styles
const styles = theme => ({
  mcButton: {
    ...theme.typography.mcButton
  },
  mcLink: {
    ...theme.mcLink
  }
});

class ExtendedForgotPasswordThemed extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
    let initialState = {
      delivery: null,
      reset: {},
      extSignUpThemedSignUpSuccess: false,
      extSignUpThemedSignUpSuccessMessage: ""
    };
    if (this.props.reset.linkCode) {
      initialState.reset = this.props.reset;
      initialState.delivery = {
        AttributeName: "email",
        DeliveryMedium: "EMAIL"
      };
    }
    this.state = initialState;
  }

  submitView() {
    if (this.state.reset.linkCode) {
      return (
        <>
          <p>Reset your password.</p>
          <ExtendedTextField
            id="password"
            key="password"
            name="password"
            type="password"
            label="New Password"
            onChange={this.handleInputChange}
            onKeyPress={this.submitWithEnter}
            color="secondary"
            required
          />
          <Box my={2}>
            <Button
              className={this.props.classes.mcButton}
              size="large"
              variant="contained"
              fullWidth
              onClick={() => this.submit()}
            >
              Submit
            </Button>
          </Box>
        </>
      );
    }

    const email =
      (this.state.delivery && this.state.delivery.Destination) ||
      this.props.authData.username;
    const emailDisplay = email ? (
      <TextField
        id="email"
        type="text"
        margin="dense"
        size="small"
        variant="outlined"
        value={email}
        disabled
        fullWidth
      />
    ) : (
      ""
    );

    return (
      <>
        <p>We have sent you a password reset email {email ? "at:" : ""}</p>
        {emailDisplay}
        <p>
          Please check your email and click on the link to reset your password.
        </p>
        <p>
          If you do not receive the reset password message within a few minutes
          of clicking the Send Reset Link button, please check your Spam or Junk
          folder.
        </p>
      </>
    );
  }

  sendView() {
    return (
      <>
        <p>Enter your email address to create a new password.</p>
        <ExtendedTextField
          id="username"
          key="username"
          name="username"
          type="text"
          label="Email Address"
          onChange={this.handleInputChange}
          onKeyPress={this.sendWithEnter}
          color="secondary"
          required
        />
        <Box my={2}>
          <Button
            className={this.props.classes.mcButton}
            size="large"
            variant="contained"
            fullWidth
            onClick={() => this.send()}
          >
            Send Reset Link
          </Button>
        </Box>
      </>
    );
  }

  submitWithEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.submit();
    }
  };

  sendWithEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.send();
    }
  };

  submit = async () => {
    const resetCode = this.state.reset.linkCode;
    const resetUsername = this.state.reset.username;
    const password = this.inputs["password"];
    const { authData = {} } = this.props;

    // For the component's super methods
    this.inputs["username"] = resetUsername;
    this.inputs["code"] = resetCode;

    const username = this.getUsernameFromInput() || authData.username;

    try {
      const data = await Auth.forgotPasswordSubmit(
        username,
        resetCode,
        password
      );
      logger.debug(data);

      this.setState({
        extSignUpThemedSignUpSuccess: true,
        extSignUpThemedSignUpSuccessMessage:
          "Password successfully changed. Please log in."
      });

      await delay(3000);

      this.setState({
        delivery: null,
        reset: {}
      });
      this.changeState("signIn");
    } catch (err) {
      if (err.code && typeof err.code === "string" && err.code.match(/code/i)) {
        console.error(err.code, err.message);
        err.message = "Reset link is not valid. Please try again.";

        this.setState({
          delivery: null,
          reset: {}
        });
      }

      this.error(usernameToEmail(err));
    }
  };

  send = async () => {
    this.inputs["username"] = (this.inputs["username"] || "").trim();

    const { authData = {} } = this.props;
    const username = this.getUsernameFromInput() || authData.username;

    try {
      const data = await Auth.forgotPassword(username, {
        tenant: this.props.site
      });
      logger.debug(data);
      this.setState({ delivery: data.CodeDeliveryDetails });
    } catch (err) {
      if (
        err.code &&
        typeof err.code === "string" &&
        err.code === "UserNotFoundException"
      ) {
        console.error(err.code, err.message);
        err.message = "Invalid email or user not found.";
      }

      this.error(usernameToEmail(err));
    }
  };

  hideAlert = () => {
    this.setState({
      extSignUpThemedSignUpSuccess: false,
      extSignUpThemedSignUpSuccessMessage: ""
    });
  };

  showComponent() {
    const { authState, authData = {} } = this.props;

    return (
      <AuthTabs
        authState={authState}
        onTabChange={tabValue => super.changeState(tabValue)}
      >
        {authState === "forgotPassword" && (
          <>
            <Box mb={2}>
              <FormGroup>
                <form>
                  {this.state.delivery || authData.username
                    ? this.submitView()
                    : this.sendView()}
                </form>
              </FormGroup>
            </Box>
            <Snackbar
              open={this.state.extSignUpThemedSignUpSuccess}
              autoHideDuration={3000}
              onClose={this.hideAlert}
            >
              <Alert onClose={this.hideAlert} severity="success">
                {this.state.extSignUpThemedSignUpSuccessMessage}
              </Alert>
            </Snackbar>
            <Divider />
            <div
              style={{
                padding: "0 25px",
                color: "gray",
                backgroundColor: "white",
                margin: "-10px auto",
                width: "22px"
              }}
            >
              OR
            </div>
            {(this.state.delivery || authData.username) &&
            !this.state.reset.linkCode ? (
              <Box mt={4} mx={"auto"} width="90px">
                <Link
                  className={this.props.classes.mcLink}
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    this.send();
                  }}
                >
                  Resend Link
                </Link>
              </Box>
            ) : (
              <Box mt={4} mx={"auto"} width="105px">
                <Link
                  className={this.props.classes.mcLink}
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    super.changeState("signIn");
                  }}
                >
                  Back to Log In
                </Link>
              </Box>
            )}
          </>
        )}
      </AuthTabs>
    );
  }
}

function usernameToEmail(err) {
  const msg = err.message;
  if (msg && typeof msg === "string") {
    err.message = msg.replace(/username/i, "Email");
  }
  return err;
}

function delay(timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

export const ExtendedForgotPassword = withStyles(styles, { withTheme: true })(
  ExtendedForgotPasswordThemed
);
