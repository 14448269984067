const PROVIDERS = ["google", "facebook", "loginwithamazon"];

const isOAuthUser = user => {
  if (user?.attributes?.identities) {
    const ids = user.attributes.identities.toLowerCase();
    return PROVIDERS.some(p => ids.includes(p));
  }
  // oauth usernames are $provider_$id
  if (user?.username) {
    const userProvider = user.username.toLowerCase().split("_")[0];
    return PROVIDERS.some(p => userProvider === p);
  }
  return false;
};

export default isOAuthUser;
