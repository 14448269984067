import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Amplify, { Auth, Hub, API } from "aws-amplify";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "./utility/Theme";
import { setThemeColors } from "./utility/Theme/helpers";
import Header from "./components/Header/";
import SignInPage from "./components/Account/SignInPage";
import SignUpPage from "./components/Account/SignUpPage";
import SignOutPage from "./components/Account/SignOutPage";
import OAuthSignInReturnPage from "./components/Account/OAuthSignInReturnPage";
import ConfirmAccountPage from "./components/Account/ConfirmAccountPage";
import UserAccount from "./components/UserAccount";
import Newsletters from "./components/Newsletters/";
import EmailAlerts from "./components/EmailAlerts";
import TextAlerts from "./components/TextAlerts";
import Contests from "./components/Contests";
import updateCookieSameSite from "./utility/cookies";
import { handleSsoPostSignInAction } from "./extended-amplify-auth/handleSsoPostActions";
import CONFIG from "./config";
import "./App.css";
import { getTenantConfig, hostnameToStation } from "./utility/tenantConfig";
import { oAuthSignOutRedirect } from "./utility/oAuthSignout";
import Loading from "./components/utils/Loading";
import { AuthenticatorWrapper } from "./extended-amplify-auth";
import ScrollToTop from "./components/utils/ScrollToTop";
import FavIcon from "./components/meta/FavIcon";

// Figure out the current site.
const site = hostnameToStation(
  window.location.hostname,
  window.location.search
);

// Endpoint will verify user with their JWT Token
const getUserAuthorizationHeader = async () => {
  const authHeader = {
    Authorization: `${(await Auth.currentSession()).getIdToken().jwtToken}`
  };
  if (process.env.REACT_APP_DEPLOY_ENV === "LOCAL") {
    authHeader["cloudfront-key"] = process.env.REACT_APP_MC_CLOUDFRONT_KEY;
  }
  return authHeader;
};

// Some config endpoints will only be secured by Basic Authorizer
const getBasicAuthorizationHeader = () => {
  const authHeader = {
    "x-api-key": CONFIG.apiGateway.APIKEY
  };
  return authHeader;
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: CONFIG.cognito.region,
    userPoolId: CONFIG.cognito.userPoolId,
    userPoolWebClientId: CONFIG.cognito.userPoolWebClientId,
    oauth: CONFIG.oauthConfig,
    authenticationFlowType: "USER_SRP_AUTH",
    cookieStorage: CONFIG.cognito.cookieStorage
  },
  API: {
    endpoints: [
      {
        name: "accounts",
        endpoint: `${CONFIG.apiGateway.URL}/accounts`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getBasicAuthorizationHeader
      },
      {
        name: "tenantConfigs",
        endpoint: `${CONFIG.apiGateway.URL}/configs`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getBasicAuthorizationHeader
      },
      {
        name: "email",
        endpoint: `${CONFIG.apiGateway.URL}/email`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getBasicAuthorizationHeader
      },
      {
        name: "userEmailAlerts",
        endpoint: `${CONFIG.apiGateway.URL}/emailalerts`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getUserAuthorizationHeader
      },
      {
        name: "extendedProfiles",
        endpoint: `${CONFIG.apiGateway.URL}/profiles`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getUserAuthorizationHeader
      },
      {
        name: "nickname",
        endpoint: `${CONFIG.apiGateway.URL}/profiles/nickname`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getBasicAuthorizationHeader
      },
      {
        name: "userNewsletters",
        endpoint: `${CONFIG.apiGateway.URL}/newsletters`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getUserAuthorizationHeader
      },
      {
        name: "newsletters",
        endpoint: `${CONFIG.apiGateway.URL}/newsletters`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getBasicAuthorizationHeader
      },
      {
        name: "textalerts",
        endpoint: `${CONFIG.apiGateway.URL}/textalerts`,
        region: CONFIG.apiGateway.REGION,
        custom_header: getUserAuthorizationHeader
      }
    ]
  }
});

class App extends React.PureComponent {
  state = {
    loadingTenant: true,
    isAuthenticated: false,
    user: null,
    authState: null,
    tenantConfig: {}
  };

  constructor(props) {
    super(props);
    this.updateAuthProps = this.updateAuthProps.bind(this);
    Hub.listen("auth", this.handleAuthEvents);
    updateCookieSameSite();
  }

  handleAuthEvents = async data => {
    const { payload } = data;

    updateCookieSameSite();

    // signIn for Google Sign in and up
    switch (payload.event) {
      case "cognitoHostedUI": {
        // Amplfiy bug leads to the Authenticator returning from social authentication with a user
        // without attributes.
        // https://github.com/aws-amplify/amplify-js/issues/3214
        // This delayed state check will return the user that has attributes
        const t = setInterval(() => {
          const userEmail =
            this.state.user && this.state.user.attributes
              ? this.state.user.attributes.email
              : null;
          if (userEmail) {
            clearInterval(t);
            this.updateProfileAtSignIn(userEmail);
          }
        }, 250);
        break;
      }
      case "signIn": {
        await handleSsoPostSignInAction(data);

        const userEmail =
          payload.data.attributes && payload.data.attributes.email
            ? payload.data.attributes.email
            : null;
        userEmail && (await this.updateProfileAtSignIn(userEmail));
        break;
      }
      case "signOut": {
        if (this.state.tenantConfig.ui) {
          window.location.assign(this.state.tenantConfig.ui.urls.backToStation);
        }
        break;
      }
      case "oAuthSignOut": {
        oAuthSignOutRedirect.preSignoutSetup();
        break;
      }
      default:
        break;
    }
  };

  updateProfileAtSignIn = async userEmail => {
    if (!this.updatedLastSignin) {
      this.updatedLastSignin = true;

      await API.put("extendedProfiles", "/async/", {
        queryStringParameters: {
          email: userEmail
        },
        body: {
          email: userEmail,
          site: site
        }
      });
    }
  };

  componentDidMount = async () => {
    const state = this.state;
    if (window.location.pathname === "/create-account" && state.authState !== 'signUp') {
      this.setState({
        authState: "signUp",
      });
    }
    const tenantConfig = await getTenantConfig(site);
    this.setState({ tenantConfig, loadingTenant: false });
    if (oAuthSignOutRedirect.postSignoutCleanup()) {
      window.location.assign(tenantConfig.ui.urls.backToStation);
    }
  };

  componentWillUnmount() {
    Hub.remove("auth", this.handleAuthEvents);
  }

  updateAuthProps(
    authState,
    authData,
    { lastAuthenticatorUpdate = null } = {}
  ) {
    this.setState({
      user: authData,
      isAuthenticated: authState === "signedIn",
      lastAuthenticatorUpdate,
      authState
    });
  }

  render() {
    // to accommodate s3 static hosting:
    // https://viastudio.com/hosting-a-reactjs-app-with-routing-on-aws-s3/
    const history = createBrowserHistory();
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
      history.replace(path);
    }

    if (this.state.loadingTenant) {
      return (
        <>
          <AuthenticatorWrapper updateAuthProps={this.updateAuthProps} />
          <Loading />
        </>
      );
    } else if (this.state.loadingTenant === false) {
      // set the theme with colors from the tenantConfig
      const theme = createTheme(
        setThemeColors(this.state.tenantConfig.ui.theme.colors)
      );
      const favicon = this.state.tenantConfig.ui.theme.favicon;

      return (
        <div className="App">
          <MuiThemeProvider theme={theme}>
            {/* Set the favicon from the tenant config, if present. */}
            {favicon && <FavIcon href={favicon} />}

            <BrowserRouter>
              {/* Scroll to top of the page on location changes. */}
              <ScrollToTop />

              <Header
                authProps={this.state}
                signInPath={"/sign-in"}
                tenantConfig={this.state.tenantConfig}
              />

              <Route
                render={props => (
                  <AuthenticatorWrapper
                    {...props}
                    authProps={this.state}
                    updateAuthProps={this.updateAuthProps}
                    signInPath={"/sign-in"}
                    tenantConfig={this.state.tenantConfig}
                    site={site}
                  />
                )}
              />
              <div>
                <Switch>
                  <Route
                    exact
                    path="/oauth/"
                    render={props => (
                      <OAuthSignInReturnPage
                        {...props}
                        authProps={this.state}
                        links={this.state.tenantConfig.ui.urls}
                      />
                    )}
                  />
                  <Route exact path="/">
                    <Redirect to="/edit-profile" />
                  </Route>
                  <Route
                    exact
                    path="/sign-in"
                    render={props => (
                      <SignInPage {...props} authProps={this.state} />
                    )}
                  />
                  <Route
                    exact
                    path="/create-account"
                    render={props => (
                      <SignUpPage 
                        {...props} 
                        authProps={this.state} 
                        updateAuthProps={this.updateAuthProps}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/account/logout"
                    render={props => (
                      <SignOutPage {...props} authProps={this.state} />
                    )}
                  />
                  <Route
                    exact
                    path="/confirm-account"
                    render={props => (
                      <ConfirmAccountPage {...props} authProps={this.state} />
                    )}
                  />
                  <Route
                    exact
                    path="/edit-profile"
                    render={props => (
                      <UserAccount
                        {...props}
                        authProps={this.state}
                        updateAuthProps={this.updateAuthProps}
                        site={site}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/newsletters"
                    render={props => (
                      <Newsletters
                        {...props}
                        tenantConfig={this.state.tenantConfig}
                        authProps={this.state}
                        site={site}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/email-alerts"
                    render={props => (
                      <EmailAlerts
                        {...props}
                        tenantConfig={this.state.tenantConfig}
                        authProps={this.state}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/text-alerts"
                    render={props => (
                      <TextAlerts
                        {...props}
                        site={site}
                        updateAuthProps={this.updateAuthProps}
                        authProps={this.state}
                        tenantConfig={this.state.tenantConfig}
                        manageAlertsUrl={
                          this.state.tenantConfig.ui.urls.manageAlerts
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/contests"
                    render={props => (
                      <Contests
                        {...props}
                        tenantConfig={this.state.tenantConfig}
                        authProps={this.state}
                      />
                    )}
                  />
                </Switch>
              </div>
            </BrowserRouter>
          </MuiThemeProvider>
        </div>
      );
    }
  }
}

export default App;
