import React from "react";
import { Redirect } from "react-router-dom";
import { Container, Link, Typography, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// styles are a placeholder for now
const styles = theme => ({
  paper: {
    ...theme.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  mcLink: {
    ...theme.typography.mcLink,
    color: theme.palette.tertiary.main
  }
});

/**
 * Display a simple link to the contest page configured in the tenant config.
 */
const Contests = props => {
  const { classes, authProps, tenantConfig } = props;

  if (authProps.authState) {
    if (authProps.isAuthenticated && authProps.user) {
      let content = (
        <Container maxWidth="sm">
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom>
              Contests
            </Typography>
            <Typography variant="body1">
              Clicking the link will redirect you to the site&apos;s contest
              page.
            </Typography>
            <Link
              className={classes.mcLink}
              href={tenantConfig.ui.urls.contests}
              target="_blank"
              rel="noreferrer"
            >
              Go to the Contests Page
            </Link>
          </Paper>
        </Container>
      );

      return <div className="box cta">{content}</div>;
    } else {
      return <Redirect to="/sign-in" />;
    }
  } else {
    return <span className="tag is-primary">Loading ...</span>;
  }
};

export default withStyles(styles, { withTheme: true })(Contests);
