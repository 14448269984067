import React from "react";
import { API } from "aws-amplify";
import { Container, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NewslettersSignUp from "./NewslettersSignUp";
import NewsletterHeader from "./NewsletterHeader";
import NewsletterCard from "./NewsletterCard";
import SnackbarAlert from "../utils/SnackbarAlert";

// styles placeholder for now
const styles = theme => ({
  root: {
    ...theme.spacing(2)
  }
});

class Newsletters extends React.Component {
  state = {
    userSubscriptionsLoaded: false,
    newsletters: [],
    newslettersSubscribed: [],
    email: "",
    isDisabled: false,
    alertStatus: { show: false, message: "" },
    subscriptionStateChange: true,
    policyConsent: ""
  };

  componentDidMount = async () => {
    await this.setUserSubscriptions();
  };

  componentDidUpdate = async () => {
    // Prevent further subscription fetch attempt if there was an alert
    if (this.state.subscriptionStateChange) {
      await this.setUserSubscriptions();
    }
  };

  // Safely fetch the user's subscriptions and put into state
  setUserSubscriptions = async () => {
    if (!this.state.userSubscriptionsLoaded) {
      if (this.props.authProps && this.props.authProps.user) {
        const email = this.props.authProps.user.attributes.email;
        const newsletterSubResponse = await this.getNewsletterSubscriptions(
          email
        );
        if (newsletterSubResponse) {
          this.setState({
            newslettersSubscribed: newsletterSubResponse.success,
            userSubscriptionsLoaded: true,
            email: email,
            isAuthenticated: false,
            subscriptionStateChange: true
          });
        }
      }
    }
  };

  getNewsletterSubscriptions = async email => {
    if (this.props.authProps && this.props.authProps.user) {
      try {
        const subscriptions = await API.get(
          "userNewsletters",
          "/subscriptions/",
          {
            queryStringParameters: {
              station: this.props.site,
              email
            }
          }
        );
        return subscriptions;
      } catch (e) {
        this.showAlert(e);
        return null;
      }
    } else {
      return null;
    }
  };

  onSetDisabled = disabled => {
    this.setState({ isDisabled: disabled, subscriptionStateChange: true });
  };

  showAlert = e => {
    console.error("Error communicating with the newsletters endpoint", e);
    this.setState({
      alertStatus: {
        show: true,
        message: "We’re sorry. We have encountered an error, please try again."
      },
      subscriptionStateChange: false
    });
  };

  hideAlert = () => {
    this.setState({
      alertStatus: {
        show: false,
        message: "",
        alertOnly: true
      },
      subscriptionStateChange: false
    });
  };

  render() {
    if (this.props.authProps.authState) {
      let newsLettersAndSubscribed = [];
      const stationNewsletters = this.props.tenantConfig.newsletters;

      // simple sign-in user
      if (!this.props.authProps.isAuthenticated && stationNewsletters.length) {
        newsLettersAndSubscribed = stationNewsletters;
      }

      // we check for authenticated
      if (this.props.authProps.isAuthenticated) {
        newsLettersAndSubscribed = stationNewsletters.map(newsletter => {
          // to prevent mutating the state, we create a deep copy to change
          const aNewsLetterAndSubscribed = Object.assign({}, newsletter);
          // we check to see if they already have subscriptions
          if (this.state.newslettersSubscribed.length) {
            this.state.newslettersSubscribed.forEach(function(nl) {
              if (aNewsLetterAndSubscribed.name === nl.newsletterId) {
                aNewsLetterAndSubscribed.status = nl.status;
              }
            });
          } else {
            // they don't have any subscriptions, we initially set the status to inactive
            aNewsLetterAndSubscribed.status = "inactive";
          }
          return aNewsLetterAndSubscribed;
        });
      }

      let newslettercontentCard;

      newslettercontentCard = newsLettersAndSubscribed.map(
        (newsletter, index) => {
          return (
            <NewsletterCard
              key={index}
              newsletter={newsletter}
              authProps={
                this.props.authProps.isAuthenticated &&
                this.props.authProps.user
                  ? this.props.authProps
                  : false
              }
              userEmail={this.state.email}
              status={newsletter.status}
              site={this.props.site}
              isDisabled={this.state.isDisabled}
              defaultImage={
                this.props.tenantConfig.newsletterConfig.defaultImage
              }
              showAlert={this.showAlert}
              policyConsent={this.state.policyConsent}
            />
          );
        }
      );

      let content = (
        <Container maxWidth="md">
          <NewsletterHeader />
          {!this.props.authProps.isAuthenticated && !this.props.authProps.user && (
            <NewslettersSignUp
              site={this.props.site}
              privacyPolicy={this.props.tenantConfig.ui.urls.privacyPolicy}
              visitorAgreement={
                this.props.tenantConfig.ui.urls.visitorAgreement
              }
              onSetDisabled={this.onSetDisabled}
              onSetEmail={email => {
                this.setState({ email: email, subscriptionStateChange: true });
                this.getNewsletterSubscriptions(
                  this.state.email,
                  this.props.site
                );
              }}
              onSetPolicyConsent={t => {
                this.setState({ policyConsent: t });
              }}
              showAlert={this.showAlert}
            />
          )}
          <Grid container spacing={2}>
            {newslettercontentCard}
          </Grid>
          <SnackbarAlert
            show={this.state.alertStatus.show}
            hide={this.hideAlert}
            duration={10000}
            message={this.state.alertStatus.message}
          />
        </Container>
      );

      return <div className="box cta">{content}</div>;
    } else {
      return <span className="tag is-primary">Loading ...</span>;
    }
  }
}

export default withStyles(styles, { withTheme: true })(Newsletters);
