import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = () => {
  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center" m={15}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default Loading;
