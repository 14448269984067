import React from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const SnackbarAlert = props => {
  return (
    <Snackbar
      open={props.show}
      autoHideDuration={props.duration}
      onClose={props.hide}
    >
      <Alert onClose={props.hide} severity={props.severity || "error"}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
