import { API } from "aws-amplify";

// CONDITION: 5 numbers (or 5 numbers + "-" + 4 numbers)
export const isValidZipCode = zip =>
  zip && typeof zip === "string" && zip.match(/^[0-9]{5}(?:-[0-9]{4})?$/);

// CONDITION: at least 4 alphanumeric characters
export const isValidNickName = nickname =>
  nickname &&
  typeof nickname === "string" &&
  nickname.match(/([^A-Za-z0-9]*[A-Za-z0-9]){4}/);

export const checkUniqueNickname = async (nickname = "", email = "") => {
  try {
    return await API.post("nickname", "", {
      body: { nickname, email }
    });
  } catch (e) {
    console.error("Error checking nickname's uniqueness", e);
    return {};
  }
};

/**
 * Validates a date string, in a standard format.
 *
 * @param {string} dateString exact string to be verified
 * @param {string} format (optional) a standard format. Default is ISO8601 format: yyyy-mm-dd
 * @returns `true` if valid; `false` otherwise
 */
export const isValidDate = (dateString, format = "yyyy-mm-dd") => {
  const dateRegexes = {
    "yyyy-mm-dd": /^((19|20)\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
    "yy-mm-dd": /^(\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
    "mm-dd-yyyy": /^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-((19|20)\d{2})$/,
    "mm-dd-yy": /^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-(\d{2})$/,
    "dd-mm-yyyy": /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-((19|20)\d{2})$/,
    "dd-mm-yy": /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-(\d{2})$/
  };

  if (Object.keys(dateRegexes).indexOf(format) === -1) {
    throw new Error("Invalid date format.");
  }

  return dateRegexes[format].test(dateString);
};
