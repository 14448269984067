import React from "react";
import { API } from "aws-amplify";
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExtendedTextField from "../../extended-material-ui/TextField";

const styles = theme => ({
  paper: {
    ...theme.paper,
    margin: "1rem auto 2rem",
    paddingTop: theme.spacing(0.5)
  },
  saveButton: {
    ...theme.typography.mcButton,
    marginTop: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0
    }
  },
  mcLink: {
    ...theme.mcLink,
    cursor: "pointer"
  },
  progressButtonWrapper: {
    ...theme.progressButtonWrapper,
    marginTop: "2px"
  },
  progressIndicator: {
    ...theme.progressIndicator
  }
});

class NewslettersSignUp extends React.Component {
  state = {
    isDisabled: "",
    newsletterEmail: "",
    email: "",
    isValid: true,
    validEmail: false,
    buttonText: "Submit",
    policyConsent: false,
    isProcessing: false
  };

  componentDidMount = () => {
    this.props.onSetDisabled(true);
  };

  resetForm = () => {
    this.setState({
      validEmail: false,
      isValid: true,
      email: "",
      buttonText: this.state.buttonText
    });
  };

  validEmailCheck = async e => {
    e.preventDefault();
    let response;
    let apiError = false;
    try {
      response = await API.post("email", "/simple-verification", {
        queryStringParameters: {
          email: this.state.email.trim()
        }
      });
    } catch (e) {
      console.error(e);
      apiError = true;
    }

    /**
     * if API is down, email validation should ignore and user can subscribe to NL's.
     * Responses - if email is valid, it returns {"isValid":true,"message":"'{{email}}' is valid"}
     *             if email is invalid, it returns {"isValid":false,"message":"'{{email}}' is invalid"}
     * Errors - Network error
     */
    if ((response && response.isValid) || apiError === true) {
      this.setState({
        email: this.state.email,
        validEmail: true
      });
      this.props.onSetEmail(this.state.email);
      this.props.onSetDisabled(false);
    } else {
      this.setState({
        buttonText: "Try again",
        isValid: false,
        email: ""
      });
      this.props.onSetDisabled(true);
    }
  };

  handlePolicyConsentChange = e => {
    const { checked } = e.target;
    if (checked === true) {
      const t = Date.now().toString();
      this.setState({
        policyConsent: t
      });
      this.props.onSetPolicyConsent(t);
    } else {
      this.setState({
        policyConsent: ""
      });
      this.props.onSetPolicyConsent("");
    }
  };

  render() {
    const { classes, privacyPolicy, visitorAgreement } = this.props;
    let content = <div></div>;

    if (!this.state.validEmail) {
      content = (
        <Paper className={classes.paper}>
          <Typography variant="h3">Enter Your Email Address</Typography>
          <Typography variant="body1">
            Once you&apos;ve filled in a valid email, you&apos;ll be able to
            sign up for newsletters. Already have an account? For a better
            experience, please{" "}
            <Link className={this.props.classes.mcLink} href="/edit-profile">
              Log in
            </Link>
            .
          </Typography>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <ExtendedTextField
                  id="simpleSignupEmail"
                  className={classes.mcExtendedField}
                  type="email"
                  label="Email Address"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  onKeyPress={e => e.key === "Enter" && this.validEmailCheck(e)}
                  disabled={this.state.isProcessing}
                  error={!this.state.isValid}
                  helperText={
                    this.state.isValid ? "" : "Invalid email address."
                  }
                  color="secondary"
                ></ExtendedTextField>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="policyConsent"
                      key="policyConsent"
                      name="policyConsent"
                      onChange={this.handlePolicyConsentChange}
                      required
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      I have read and agree to the&nbsp;
                      <Link
                        className={this.props.classes.mcLink}
                        href={visitorAgreement}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visitor Agreement
                      </Link>
                      &nbsp;and&nbsp;
                      <Link
                        className={this.props.classes.mcLink}
                        href={privacyPolicy}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.progressButtonWrapper}>
                  <Button
                    className={classes.saveButton}
                    color="primary"
                    variant="contained"
                    onClick={this.validEmailCheck}
                    disabled={
                      !this.state.email ||
                      !this.state.policyConsent ||
                      this.state.isProcessing
                    }
                    fullWidth
                  >
                    {this.state.buttonText}
                  </Button>
                  {this.state.isProcessing && (
                    <CircularProgress
                      size={24}
                      className={classes.progressIndicator}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      );
    } else {
      content = (
        <Paper className={classes.paper}>
          <Typography variant="h3" className="emailValidMessage">
            Thank You. Please Select Your Newsletters
          </Typography>
          <Typography variant="body2">
            Once your newsletters are selected you will receive them in your
            email at {this.state.email}. Already have an account? For a better
            experience, please{" "}
            <Link className={this.props.classes.mcLink} href="/sign-in">
              Log in
            </Link>
            .{" "}
            <Link onClick={this.resetForm} className={classes.mcLink}>
              Click here to change your email
            </Link>
            .
          </Typography>
        </Paper>
      );
    }

    return <div>{content}</div>;
  }
}

export default withStyles(styles, { withTheme: true })(NewslettersSignUp);
