import { createMuiTheme } from "@material-ui/core/styles";
//import { createTheme as createMuiTheme } from "@material-ui/core/styles";

export function createTheme(colors) {
  // if we want to access the baseDefaultTheme in the overrides, we need a var to reference it
  const baseDefaultTheme = createMuiTheme({});

  // in case there are no colors defined
  const palettePrimary = colors ? colors.primary[500].hex : `#666666.hex`,
    paletteSecondary = colors ? colors.secondary[500].hex : `#333333.hex`,
    paletteTertiary = colors ? colors.tertiary[500].hex : `#337ab7.hex`;

  const theme = createMuiTheme({
    spacing: 10,
    palette: {
      primary: {
        main: `${palettePrimary}`
      },
      secondary: {
        main: `${paletteSecondary}`
      },
      tertiary: {
        main: `${paletteTertiary}`
      }
    },
    typography: {
      h2: {
        margin: "0 0 1rem",
        fontSize: "1.5rem",
        fontWeight: 500
      },
      h3: {
        margin: "1rem 0",
        fontSize: "1.15rem",
        fontWeight: 400
      },
      tab: {
        fontSize: "1rem",
        fontWeight: 700,
        textTransform: "none"
      },
      mcButton: {
        backgroundColor: colors.secondary[500].hex,
        color: baseDefaultTheme.palette.common.white,
        "&:hover": {
          backgroundColor: colors.secondary[300].hex
        }
      },
      mcSignInButton: {
        height: "35px",
        marginRight: "25px",
        marginLeft: "auto",
        padding: "5px",
        backgroundColor: baseDefaultTheme.palette.common.white,
        color: baseDefaultTheme.palette.common.black,
        fontSize: ".9rem",
        textTransform: "uppercase",
        "&:hover": {
          backgroundColor: colors.primary[50].hex
        },
        [baseDefaultTheme.breakpoints.down("md")]: {
          margin: "1em 0",
          borderRadius: 0
        }
      }
    },
    toolbarMargin: {
      ...baseDefaultTheme.mixins.toolbar,
      marginBottom: "2em",
      [baseDefaultTheme.breakpoints.down("md")]: {
        marginBottom: "1em"
      }
    },
    logo: {
      height: "3em",
      marginLeft: "25px",
      [baseDefaultTheme.breakpoints.down("md")]: {
        display: "block",
        height: "2.5em",
        marginLeft: "auto"
      }
    },
    paper: {
      padding: baseDefaultTheme.spacing(2),
      color: baseDefaultTheme.palette.grey[800]
    },
    button: {
      height: 48,
      padding: "0 30px",
      backgroundColor: colors.primary[500].hex,
      color: colors.secondary[500].hex,
      border: 0,
      boxShadow: baseDefaultTheme.shadows[5],
      "&:hover": {
        backgroundColor: colors.secondary[500].hex,
        color: colors.primary[500].hex
      }
    },
    mcLink: {
      color: colors.tertiary[500].hex
    },
    progressButtonWrapper: {
      position: "relative",
      marginTop: baseDefaultTheme.spacing(1)
    },
    progressIndicator: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  });

  return theme;
}
