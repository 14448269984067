import { API } from "aws-amplify";

/**
 * Update the user's email alert subscriptions.
 *
 * @param {string} email
 * @param {string} newsletterId
 * @param {"active" | "inactive"} status
 */
export const postSubscription = async (email, newsletterId, status) => {
  await API.post("userEmailAlerts", ``, {
    queryStringParameters: {
      email: email
    },
    body: {
      emailAlertId: newsletterId,
      status: status
    }
  });
};

/**
 * Get the user's subscriptions.
 *
 * @param {string} email
 */
export const getSubscriptions = async email => {
  const profile = await API.get("extendedProfiles", `/${email}`, {
    queryStringParameters: {
      emailAlertSubscriptionsOnly: true
    }
  });

  return profile.subscriptions;
};
