import React from "react";
import { TextField } from "@material-ui/core";

const ExtendedTextField = props => {
  return (
    <TextField
      {...props}
      margin="normal"
      size="small"
      variant="outlined"
      fullWidth
    />
  );
};

export default ExtendedTextField;
